import React from "react";
import { useAppSelector } from "../../app/hooks";
import { ProfileCard } from "./ProfileCard";
import { Banner } from "../../utils/components/Common";

const Profile = (): JSX.Element => {
  const isLoggedIn = useAppSelector((state) => state.auth.isLoggedIn);
  const user = useAppSelector((state) => state.user.user);

  return (
    <>
      {user && isLoggedIn ? (
        <ProfileCard user={user} />
      ) : (
        <Banner
          alert
          heading="Protected Route"
          message="This is a protected route. Authentication required."
        />
      )}
    </>
  );
};

export default Profile;
