import React, { useState, useEffect } from "react";
import { object, string } from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

/* Components */
import ClinicianDetailFormComponent from "../../utils/components/DetailForm";
import { LoadingScreen } from "../../utils/components/Common";

/* APIs - Utils */
import { Clinician, useUpdateClinicianMutation } from "./clinicianApi";
import { toastQueryError, toastQuerySuccess } from "../../utils/toasts";
import { usePrompt } from "../../utils/hooks";

/* Under dev; can possibly go to config */
const REGEX_MOBILE_AU =
  /^(?:\+?(61))? ?(?:\((?=.*\)))?(0?[2-57-8])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/;
const REGEX_POSTCODE_AU =
  /(0[289][0-9]{2})|([1345689][0-9]{3})|(2[0-8][0-9]{2})|(290[0-9])|(291[0-4])|(7[0-4][0-9]{2})|(7[8-9][0-9]{2})/;

type DetailFormProps = {
  data: Clinician;
};

const schema = object().shape({
  first_name: string().required(),
  last_name: string().required(),
  medical_centre: string().required(),
  work_phone: string()
    .matches(REGEX_MOBILE_AU, {
      message:
        "Phone Number is not valid. Please enter in area code format. 0XXXX",
      excludeEmptyString: true,
    })
    .nullable(),
  personal_phone: string()
    .matches(REGEX_MOBILE_AU, {
      message:
        "Phone Number is not valid. Please enter in area code format. 0XXXX",
      excludeEmptyString: true,
    })
    .nullable(),
  work_email: string().email().nullable(),
  personal_email: string().email().nullable(),
  contact_preference: string().nullable(),
  payment_preference: string().nullable(),
  notes: string().nullable(),
  street: string().nullable(),
  city: string().nullable(),
  post_code: string()
    .notRequired()
    .matches(REGEX_POSTCODE_AU, {
      message: "Postcode is not valid.",
      excludeEmptyString: true,
    })
    .nullable(),
  state: string().nullable(),
  consent_status: string().nullable(),
});

export const ClinicianDetailForm = ({ data }: DetailFormProps): JSX.Element => {
  const [updatedData, setUpdatedData] = useState(data);
  const [updateClinician, { isLoading, isSuccess, isError, error }] =
    useUpdateClinicianMutation();
  const [confirmDataModalOpen, setConfirmDataModalOpen] = useState(false);
  const {
    handleSubmit,
    control,
    reset,
    formState: { isDirty, dirtyFields },
  } = useForm<Clinician>({
    resolver: yupResolver(schema),
    defaultValues: updatedData,
    mode: "onChange",
  });

  const onSubmit = (updatedData: Clinician) => {
    setUpdatedData(updatedData);
    updateClinician(updatedData);
  };

  useEffect(() => {
    setConfirmDataModalOpen(false);
    if (isSuccess) {
      toastQuerySuccess("Successfully updated Clinician..");
      reset(updatedData);
    }
    if (isError && error) {
      toastQueryError(error);
    }
  }, [isLoading, isSuccess, isError, error]);

  usePrompt({
    message:
      "Unsaved changes may be lost.\nAre you sure you want to leave the page?",
    when: isDirty,
  });

  return (
    <>
      {isLoading ? <LoadingScreen blackOut /> : null}
      <ClinicianDetailFormComponent>
        <ClinicianDetailFormComponent.Content onSubmit={handleSubmit(onSubmit)}>
          {confirmDataModalOpen ? (
            <ClinicianDetailFormComponent.Modal
              setModalOpen={setConfirmDataModalOpen}
              header="Please verify changes to fields"
              body={`${JSON.stringify(Object.keys(dirtyFields), null, 4)}`}
            >
              <ClinicianDetailFormComponent.Button
                type="button"
                txtColor="text-red-500"
                bgColor="bg-transparent"
                onClick={() => setConfirmDataModalOpen(false)}
              >
                CLOSE
              </ClinicianDetailFormComponent.Button>
              <ClinicianDetailFormComponent.Button
                type="submit"
                txtColor="text-white"
                bgColor="bg-emerald-500"
                bgHover="hover:bg-emerald-600"
              >
                Save Changes
              </ClinicianDetailFormComponent.Button>
            </ClinicianDetailFormComponent.Modal>
          ) : null}
          <ClinicianDetailFormComponent.Header>
            Clinician Information
          </ClinicianDetailFormComponent.Header>
          <ClinicianDetailFormComponent.Row>
            <ClinicianDetailFormComponent.Group>
              <ClinicianDetailFormComponent.Label>
                First Name
              </ClinicianDetailFormComponent.Label>
              <ClinicianDetailFormComponent.ClinicianInput
                control={control}
                name="first_name"
                type="text"
              />
            </ClinicianDetailFormComponent.Group>
            <ClinicianDetailFormComponent.Group>
              <ClinicianDetailFormComponent.Label>
                Last Name
              </ClinicianDetailFormComponent.Label>
              <ClinicianDetailFormComponent.ClinicianInput
                control={control}
                name="last_name"
                type="text"
              />
            </ClinicianDetailFormComponent.Group>
            <ClinicianDetailFormComponent.Group>
              <ClinicianDetailFormComponent.Label>
                Medical Centre
              </ClinicianDetailFormComponent.Label>
              <ClinicianDetailFormComponent.ClinicianInput
                control={control}
                name="medical_centre"
                type="text"
              />
            </ClinicianDetailFormComponent.Group>
            <ClinicianDetailFormComponent.Group>
              <ClinicianDetailFormComponent.Label>
                Work phone number
              </ClinicianDetailFormComponent.Label>
              <ClinicianDetailFormComponent.ClinicianInput
                control={control}
                name="work_phone"
                type="text"
              />
            </ClinicianDetailFormComponent.Group>
            <ClinicianDetailFormComponent.Group>
              <ClinicianDetailFormComponent.Label>
                Personal phone number
              </ClinicianDetailFormComponent.Label>
              <ClinicianDetailFormComponent.ClinicianInput
                control={control}
                name="personal_phone"
                type="text"
              />
            </ClinicianDetailFormComponent.Group>
            <ClinicianDetailFormComponent.Group>
              <ClinicianDetailFormComponent.Label>
                Work email
              </ClinicianDetailFormComponent.Label>
              <ClinicianDetailFormComponent.ClinicianInput
                control={control}
                name="work_email"
                type="text"
              />
            </ClinicianDetailFormComponent.Group>
            <ClinicianDetailFormComponent.Group>
              <ClinicianDetailFormComponent.Label>
                Personal email
              </ClinicianDetailFormComponent.Label>
              <ClinicianDetailFormComponent.ClinicianInput
                control={control}
                name="personal_email"
                type="text"
              />
            </ClinicianDetailFormComponent.Group>
            <ClinicianDetailFormComponent.Group>
              <ClinicianDetailFormComponent.Label>
                Contact Preference
              </ClinicianDetailFormComponent.Label>
              <ClinicianDetailFormComponent.ClinicianInput
                control={control}
                name="contact_preference"
                type="select"
                selectType="contact_choices"
              />
            </ClinicianDetailFormComponent.Group>
            <ClinicianDetailFormComponent.Group>
              <ClinicianDetailFormComponent.Label>
                Consent Status
              </ClinicianDetailFormComponent.Label>
              <ClinicianDetailFormComponent.ClinicianInput
                control={control}
                name="consent_status"
                type="select"
                selectType="clinician_consent_choices"
              />
            </ClinicianDetailFormComponent.Group>
            <ClinicianDetailFormComponent.Group>
              <ClinicianDetailFormComponent.Label>
                Payment Preference
              </ClinicianDetailFormComponent.Label>
              <ClinicianDetailFormComponent.ClinicianInput
                control={control}
                name="payment_preference"
                type="select"
                selectType="payment"
              />
            </ClinicianDetailFormComponent.Group>
            <ClinicianDetailFormComponent.Group>
              <ClinicianDetailFormComponent.Label>
                Participants Allocated
              </ClinicianDetailFormComponent.Label>
              <ClinicianDetailFormComponent.ClinicianInput
                control={control}
                name="num_allocated_participants"
                type="text"
                disabled
              />
            </ClinicianDetailFormComponent.Group>
          </ClinicianDetailFormComponent.Row>
          <ClinicianDetailFormComponent.Header>
            Address
          </ClinicianDetailFormComponent.Header>
          <ClinicianDetailFormComponent.Row>
            <ClinicianDetailFormComponent.Group>
              <ClinicianDetailFormComponent.Label>
                Street Name
              </ClinicianDetailFormComponent.Label>
              <ClinicianDetailFormComponent.ClinicianInput
                control={control}
                name="street"
                type="text"
              />
            </ClinicianDetailFormComponent.Group>
            <ClinicianDetailFormComponent.Group>
              <ClinicianDetailFormComponent.Label>
                City
              </ClinicianDetailFormComponent.Label>
              <ClinicianDetailFormComponent.ClinicianInput
                control={control}
                name="city"
                type="text"
              />
            </ClinicianDetailFormComponent.Group>
            <ClinicianDetailFormComponent.Group>
              <ClinicianDetailFormComponent.Label>
                Post Code
              </ClinicianDetailFormComponent.Label>
              <ClinicianDetailFormComponent.ClinicianInput
                control={control}
                name="post_code"
                type="text"
              />
            </ClinicianDetailFormComponent.Group>
            <ClinicianDetailFormComponent.Group>
              <ClinicianDetailFormComponent.Label>
                State
              </ClinicianDetailFormComponent.Label>
              <ClinicianDetailFormComponent.ClinicianInput
                control={control}
                name="state"
                type="select"
                selectType="state"
                labelAsValue
              />
            </ClinicianDetailFormComponent.Group>
          </ClinicianDetailFormComponent.Row>
          <ClinicianDetailFormComponent.Row>
            <ClinicianDetailFormComponent.Button
              type="button"
              txtColor="text-white"
              bgColor="bg-blue-500"
              bgHover="hover:bg-blue-600"
              onClick={() => setConfirmDataModalOpen(true)}
            >
              Update Clinician
            </ClinicianDetailFormComponent.Button>
            <ClinicianDetailFormComponent.Button
              type="button"
              txtColor="text-white"
              bgColor="bg-slate-500"
              bgHover="hover:bg-slate-600"
              onClick={() => reset(updatedData)}
            >
              Reset
            </ClinicianDetailFormComponent.Button>
          </ClinicianDetailFormComponent.Row>
        </ClinicianDetailFormComponent.Content>
      </ClinicianDetailFormComponent>
    </>
  );
};
