import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { TotalSpendByMonthTransformed } from "./PaymentReportApi";

type BarChartComponentProps = {
  data: TotalSpendByMonthTransformed[] | undefined;
  xAxisKey: string;
  barKeys: { dataKey: string; fill: string; name: string }[];
};

export const BarChartComponent = ({
  data,
  xAxisKey,
  barKeys,
}: BarChartComponentProps): JSX.Element => {
  return (
    <ResponsiveContainer width="100%" height={800}>
      <BarChart
        data={data}
        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
      >
        <XAxis
          dataKey={xAxisKey}
          angle={45}
          textAnchor={"start"}
          tick={{ fontSize: 14 }}
        />
        <YAxis />
        <Tooltip />
        <Legend
          wrapperStyle={{ padding: "70px", borderRadius: "5px", zIndex: 10 }}
        />
        {barKeys.map((bar) => (
          <Bar
            key={bar.dataKey}
            dataKey={bar.dataKey}
            fill={bar.fill}
            name={bar.name}
          />
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
};
