import React from "react";

/* Components */
import { ClinicianAddForm } from "./ClinicianAddForm";

const ClinicianAdd = (): JSX.Element => {
  return (
    <>
      <ClinicianAddForm
        setAddClinicianModalOpen={() => {
          null;
        }}
        setAddedClinician={() => {
          null;
        }}
      />
    </>
  );
};

export default ClinicianAdd;
