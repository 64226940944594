import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { toast, Flip } from "react-toastify";
import {
  isDjangoErrorResponse,
  isSecureDataErrorResponse,
} from "../features/auth/authSlice";

export function toastQueryError(
  error: FetchBaseQueryError | SerializedError | undefined
): void {
  const message_error = "Error Encountered: ";
  const message_unexpected_error =
    "An Unexpected Error has Occurred. Please contact the MOTIVATE C team.";

  if (error) {
    if ("data" in error) {
      if (isDjangoErrorResponse(error.data)) {
        toast.error(message_error + error.data.detail, {
          position: "top-right",
        });
      } else if (isSecureDataErrorResponse(error.data)) {
        toast.error(message_error + error.data.error, {
          position: "top-right",
          autoClose: false,
        });
      } else {
        toast.error(message_unexpected_error, {
          position: "top-right",
        });
      }
    } else {
      toast.error(message_unexpected_error, {
        position: "top-right",
      });
    }
  }
}

export function toastQuerySuccess(message: string): void {
  toast.success(message, {
    autoClose: 2000,
    position: "top-center",
    transition: Flip,
  });
}
