import React from "react";

interface SectionHeaderProps {
  title: string;
}

export const SectionHeader: React.FC<SectionHeaderProps> = ({
  title,
}): JSX.Element => {
  return (
    <div
      className="mb-6"
      style={{
        alignSelf: "flex-start",
        justifyContent: "flex-start",
        textAlign: "left",
      }}
    >
      <h1 className="font-bold text-xl">{title}</h1>
    </div>
  );
};
