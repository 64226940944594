import React, { useEffect, useState } from "react";

/* Config */
import config from "../../app/config.json";

/* Components */
import {
  Banner,
  Container,
  LoadingScreen,
  LinkButton,
} from "../../utils/components/Common";
import PaymentReportComponent from "./PaymentReport";

/* APIs - Hooks - Utils */
import { User } from "../user/userApi";
import { toastQueryError } from "../../utils/toasts";
import { useGetInfoQuery } from "../info/infoApi";

type PaymentListPanelProps = {
  user: User;
  isLoggedIn: boolean;
};

const PaymentListPanel = ({
  user,
  isLoggedIn,
}: PaymentListPanelProps): JSX.Element => {
  const [processing, setProcessing] = useState(true);
  const { data, isLoading, isFetching, isError, error, refetch } =
    useGetInfoQuery();

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (isLoading || isFetching) {
      setProcessing(true);
    } else {
      setProcessing(false);
    }
    if (isError && error) {
      toastQueryError(error);
    }
  }, [isLoading, isFetching, isError, error]);

  return (
    <>
      {processing ? <LoadingScreen /> : null}
      {/* TODO: Provision Payment List Panel similar to Clinician and Participant */}

      {user && isLoggedIn && data ? (
        <Container padding="px-16 py-6">
          <Banner
            heading="Feature Partially Developed"
            message="This feature is currently under development and may change over time."
          />
          <LinkButton
            txtColor="text-white"
            bgColor="bg-blue-500"
            bgHover="hover:bg-blue-600"
            linkTo={`${config.urls.base.url}${config.urls.payments.download.url}`}
            target="_blank"
            redirect
          >
            {config.urls.payments.download.displayName}
          </LinkButton>
          <LinkButton
            txtColor="text-white"
            bgColor="bg-blue-500"
            bgHover="hover:bg-blue-600"
            linkTo={`${config.urls.base.url}${config.urls.summaryPaymentFloats.download.url}`}
            target="_blank"
            redirect
          >
            {config.urls.summaryPaymentFloats.download.displayName}
          </LinkButton>
          <LinkButton
            txtColor="text-white"
            bgColor="bg-blue-500"
            bgHover="hover:bg-blue-600"
            linkTo={`${config.urls.base.url}${config.urls.detailedPaymentFloats.download.url}`}
            target="_blank"
            redirect
          >
            {config.urls.detailedPaymentFloats.download.displayName}
          </LinkButton>
          <PaymentReportComponent
            user={user}
            isLoggedIn={isLoggedIn}
          ></PaymentReportComponent>
        </Container>
      ) : null}
    </>
  );
};

export default PaymentListPanel;
