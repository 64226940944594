import { object, string } from "yup";
import dayjs from "dayjs";

/* Under dev; can possibly go to config */
const REGEX_MOBILE_AU =
  /^(?:\+?(61))? ?(?:\((?=.*\)))?(0?[2-57-8])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/;
const REGEX_POSTCODE_AU =
  /(0[289][0-9]{2})|([1345689][0-9]{3})|(2[0-8][0-9]{2})|(290[0-9])|(291[0-4])|(7[0-4][0-9]{2})|(7[8-9][0-9]{2})/;

const schema = object().shape(
  {
    record_id: string(),

    /* Registration */
    mobile: string().matches(REGEX_MOBILE_AU, {
      message: "Mobile number is not valid.",
      excludeEmptyString: true,
    }),
    register_date_time: string(),
    randomised_participant: string(),
    randomised_date_time: string(),
    stop_message_date_time: string(),
    allocated_navigator: string(),
    project_cutoff_date_time: string(),
    trial_discovery: string(),
    trial_discovery_other: string(),

    /* Status */
    status: string(),
    reminder_date: string(),
    due_date: string(),
    cutoff_date: string(),
    has_lapsed: string(),
    status_update_date_time: string(),
    optional_data: string(),
    notes_box: string(),

    /* Eligibility */
    self_report_hep_c: string(),
    on_current_hep_c_treatment: string(),
    age_18_or_older: string(),
    has_medicare_card: string(),
    resident_in_australia: string(),
    previously_enrolled: string(),
    able_complete_follow_up: string(),
    contraindication_treatment: string(),
    able_to_provide_consent: string(),
    confirm_eligible_date_time: string(),

    /* Consent */
    has_read_pi_sheet: string(),
    understands_procedures_risk: string(),
    queries_answered: string(),
    freely_agrees_to_participate: string(),
    aware_of_project_withdrawal: string(),
    consents_to_pi_use: string(),
    navigator_confirm_consent: string(),
    consent_date_time: string(),
    consented_by: string(),
    future_research_opt: string(),
    consent_comments: string(),

    /* Participant Information */
    first_name: string()
      .ensure()
      .when(["date_of_birth", "last_name"], {
        is: (a: string, b: string) => a !== "" || b !== "",
        then: string().required(
          "First Name is required if filling in Date of Birth / Last Name"
        ),
      }),
    last_name: string()
      .ensure()
      .when(["date_of_birth", "first_name"], {
        is: (a: string, b: string) => a !== "" || b !== "",
        then: string().required(
          "Last Name is required if filling in Date of Birth / First Name"
        ),
      }),
    date_of_birth: string()
      .ensure()
      .when(["first_name", "last_name"], {
        is: (a: string, b: string) => a !== "" || b !== "",
        then: string()
          .required(
            "Date of Birth is required if filling in First Name / Last Name"
          )
          .test(
            "checkAge",
            "Age must be eighteen or over.",
            function (dob, context) {
              /* valid = <now> - <dob> in years */
              const valid = dayjs().year() - dayjs(dob).year() >= 18;
              return !valid ? context.createError() : valid;
            }
          ),
      }),

    sex: string(),
    payment_method: string(),
    mailing_street: string(),
    mailing_city: string(),
    mailing_post_code: string().notRequired().matches(REGEX_POSTCODE_AU, {
      message: "Postcode is not valid.",
      excludeEmptyString: true,
    }),
    mailing_state: string(),
    preferred_name: string(),
    preferred_mobile: string(),
    participant_email_address: string(),

    /* Clinician */
    did_request_prescriber: string(),
    clinician_letter_sent: string(),
    requested_prescriber_id: string(),
    randomised_clinician: string(),
    allocated_prescriber_id: string(),
    clinician_payment_amount: string(),

    /* HCV test results */
    hcv_test_results: string(),
    hcv_test_date: string(),
    hcv_test_comments: string(),
    hcv_test_confirm_date_time: string(),

    daa_meeting_date_time: string(),
    daa_verified_date_time: string(),
    daa_verified: string(),
    daa_tmt_schedule: string(),
    daa_tmt_start_date_time: string(),
    daa_tmt_complete_date_time: string(),

    /* DAA Follow-up 
      Not represented
      daaf_live, daaf_sms_status,
      */
    daaf_sms_consented: string(),
    daaf_days_tmt_missed: string(),
    daaf_mt_incentive_received: string(),
    daaf_mt_incentive_problems_text: string(),
    daaf_any_problems: string(),
    daaf_any_problems_text: string(),
    daaf_eot_checkin_date_time: string(),
    daaf_eot_checkin_meeting_date_time: string(),

    /* SVR test results */
    svr_test_results: string(),
    svr_test_date: string(),
    svr_test_comments: string(),
    svr_test_confirm_date_time: string(),
  },
  [
    ["date_of_birth", "first_name"],
    ["date_of_birth", "last_name"],
    ["first_name", "last_name"],
  ]
);

export default schema;
