import { createApi } from "@reduxjs/toolkit/query/react";
import customFetchBaseQuery from "../../app/customFetchBaseQuery";
import { setCredentials, unsetCredentials } from "./authSlice";
import { unsetUser, setUser } from "../user/userSlice";
import { User } from "../user/userApi";
import { getCSRFToken } from "./csrf";

export interface AuthLoginResponse {
  user: User;
  access_token: string;
  refresh_token: string;
  csrf_token: string;
  isLoggedIn: boolean;
}
export interface AuthLoginRequest {
  code: string | null;
  state: string | null;
  url: string;
}

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: customFetchBaseQuery,
  endpoints: (builder) => ({
    csrfPing: builder.mutation<void, void>({
      query() {
        return {
          url: "ping",
          method: "GET",
          headers: {
            "X-CSRFToken": getCSRFToken(),
          },
        };
      },
    }),
    login: builder.mutation<AuthLoginResponse, AuthLoginRequest>({
      query({ code, state, url }) {
        return {
          url: "auth/" + url,
          method: "POST",
          body: { code: code, state: state },
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setCredentials(data));
          dispatch(setUser(data.user));
        } catch (error) {
          // Do something else when error is caught
          // Error directly handled by mutations on usage
          // https://redux-toolkit.js.org/rtk-query/usage/mutations#frequently-used-mutation-hook-return-values
        }
      },
    }),
    logout: builder.mutation<void, void>({
      query() {
        return {
          url: "auth/logout/",
          method: "POST",
          credentials: "include",
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(unsetCredentials());
          dispatch(unsetUser());
        } catch (error) {
          // Do something else when error is caught
          // Error directly handled by mutations on usage
          // https://redux-toolkit.js.org/rtk-query/usage/mutations#frequently-used-mutation-hook-return-values
        }
      },
    }),
    middlewareLogin: builder.query<void, void>({
      /* 
      Need to perform this query to fetch the middlware login view 
      (unstyled django login) and parse through the needed token (csrfmiddlware)
      The returned view in html is all captured as text and further processed / parsed to get the hidden input value.
      */
      query() {
        return {
          url: "auth/okta/url/",
          method: "GET",
          credentials: "include",
          responseHandler: (response) => response.text(),
        };
      },
      async onQueryStarted(args, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {
          // Do something else when error is caught
          // Error directly handled by mutations on usage
          // https://redux-toolkit.js.org/rtk-query/usage/mutations#frequently-used-mutation-hook-return-values
        }
      },
    }),
  }),
});

export const {
  useCsrfPingMutation,
  useLoginMutation,
  useMiddlewareLoginQuery,
  useLogoutMutation,
} = authApi;
