import React from "react";
import { SectionHeader } from "./Payment.sectionheader";

import { TotalSpendByPaymentMethod } from "./PaymentReportApi";
import { Item } from "./Payment.item";

/* Config */
import config from "../../app/config.json";

interface PaymentCardProps {
  data: TotalSpendByPaymentMethod;
  fontSize: string;
}

export const PaymentCard: React.FC<PaymentCardProps> = ({
  data,
  fontSize,
}): JSX.Element => {
  const fees = (
    total_paid_with_fees: string,
    total_paid_without_fees: string
  ): number => {
    const fees =
      parseFloat(total_paid_with_fees) - parseFloat(total_paid_without_fees);
    return parseFloat(fees.toFixed(2));
  };

  const getPaymentMethodLabel = (method: string): string => {
    return (
      config.constants.payments.payment_method_labels[
        method as keyof typeof config.constants.payments.payment_method_labels
      ] || "Unknown Payment Method"
    );
  };

  const {
    total_paid_with_fees,
    total_paid_without_fees,
    payment_method,
    total_committed,
  } = data;

  const feesValue = fees(total_paid_with_fees, total_paid_without_fees);
  const paymentMethodLabel = getPaymentMethodLabel(payment_method);

  return (
    <Item
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <SectionHeader title={paymentMethodLabel + " Spend ($)"} />
      <div
        style={{
          alignSelf: "center",
          textAlign: "center",
          marginTop: "auto",
          marginBottom: "auto",
          fontSize: fontSize,
        }}
      >
        <p>{total_paid_without_fees}</p>
      </div>
      <div
        style={{
          color: "grey",
          textAlign: "right",
        }}
      >
        <p className="text-sm my-4">
          {feesValue} fees, {total_committed} committed
        </p>
      </div>
    </Item>
  );
};
