import React, { useState, useEffect } from "react";
import { object, string } from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

/* Components */
import ClinicianDetailFormComponent from "../../utils/components/DetailForm";
import { LoadingScreen } from "../../utils/components/Common";

/* APIs - Utils */
import { Clinician, useAddClinicianMutation } from "./clinicianApi";
import { toastQueryError, toastQuerySuccess } from "../../utils/toasts";
import { usePrompt } from "../../utils/hooks";

/* Under dev; can possibly go to config */
const REGEX_MOBILE_AU =
  /^(?:\+?(61))? ?(?:\((?=.*\)))?(0?[2-57-8])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/;
const REGEX_POSTCODE_AU =
  /(0[289][0-9]{2})|([1345689][0-9]{3})|(2[0-8][0-9]{2})|(290[0-9])|(291[0-4])|(7[0-4][0-9]{2})|(7[8-9][0-9]{2})/;

const schema = object().shape({
  first_name: string().required(),
  last_name: string().required(),
  medical_centre: string().required(),
  work_phone: string().matches(REGEX_MOBILE_AU, {
    message:
      "Phone Number is not valid. Please enter in area code format. 0XXXX",
    excludeEmptyString: true,
  }),
  personal_phone: string().matches(REGEX_MOBILE_AU, {
    message:
      "Phone Number is not valid. Please enter in area code format. 0XXXX",
    excludeEmptyString: true,
  }),
  work_email: string().email(),
  personal_email: string().email(),
  contact_preference: string(),
  notes: string(),
  street: string(),
  city: string(),
  post_code: string().notRequired().matches(REGEX_POSTCODE_AU, {
    message: "Postcode is not valid.",
    excludeEmptyString: true,
  }),
  state: string(),
  consent_status: string(),
});

type ClinicianAddFormProps = {
  setAddClinicianModalOpen: (open: boolean) => void;
  setAddedClinician: (clinician: Clinician | undefined) => void;
};
export const ClinicianAddForm = ({
  setAddClinicianModalOpen,
  setAddedClinician,
}: ClinicianAddFormProps): JSX.Element => {
  const newClinician: Partial<Clinician> = {
    first_name: "",
    last_name: "",
    medical_centre: "",
    work_phone: "",
    personal_phone: "",
    work_email: "",
    personal_email: "",
    contact_preference: "",
    notes: "",
    street: "",
    city: "",
    post_code: "",
    state: "",
    consent_status: "",
  };
  const [updatedData, setUpdatedData] = useState(newClinician);
  const [addClinician, { isLoading, isSuccess, isError, error }] =
    useAddClinicianMutation();
  const [modalOpen, setModalOpen] = useState(false);
  const {
    getValues,
    control,
    reset,
    formState: { isDirty },
  } = useForm<Clinician>({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const onSubmit = (updatedData: Clinician) => {
    addClinician(updatedData)
      .unwrap()
      .then((returnedData) => {
        setUpdatedData(returnedData);
        setModalOpen(false);
        setAddClinicianModalOpen(false);
        setAddedClinician(returnedData);
        toastQuerySuccess("Successfully added Clinician..");
      })
      .catch((returnedError) => toastQueryError(returnedError));
  };

  useEffect(() => {
    setModalOpen(false);
    if (isSuccess) {
      reset(updatedData);
    }
    if (isError && error) {
      toastQueryError(error);
    }
  }, [isLoading, isSuccess, isError, error]);

  usePrompt({
    message:
      "Unsaved changes may be lost.\nAre you sure you want to leave the page?",
    when: isDirty,
  });

  return (
    <>
      {isLoading ? <LoadingScreen blackOut /> : null}
      <ClinicianDetailFormComponent>
        <ClinicianDetailFormComponent.Content>
          {modalOpen ? (
            <ClinicianDetailFormComponent.Modal
              setModalOpen={setModalOpen}
              header="Please verify to continue"
              body="You are about to add a Clinician Entry, click CONFIRM to continue"
            >
              <ClinicianDetailFormComponent.Button
                type="button"
                txtColor="text-red-500"
                bgColor="bg-transparent"
                onClick={() => setModalOpen(false)}
              >
                CLOSE
              </ClinicianDetailFormComponent.Button>
              <ClinicianDetailFormComponent.Button
                type="button"
                txtColor="text-white"
                bgColor="bg-emerald-500"
                bgHover="hover:bg-emerald-600"
                onClick={() => onSubmit(getValues())}
              >
                CONFIRM
              </ClinicianDetailFormComponent.Button>
            </ClinicianDetailFormComponent.Modal>
          ) : null}

          <ClinicianDetailFormComponent.Header>
            Clinician Information
          </ClinicianDetailFormComponent.Header>
          <ClinicianDetailFormComponent.Row>
            <ClinicianDetailFormComponent.Group>
              <ClinicianDetailFormComponent.Label>
                First Name
              </ClinicianDetailFormComponent.Label>
              <ClinicianDetailFormComponent.ClinicianInput
                control={control}
                name="first_name"
                type="text"
              />
            </ClinicianDetailFormComponent.Group>
            <ClinicianDetailFormComponent.Group>
              <ClinicianDetailFormComponent.Label>
                Last Name
              </ClinicianDetailFormComponent.Label>
              <ClinicianDetailFormComponent.ClinicianInput
                control={control}
                name="last_name"
                type="text"
              />
            </ClinicianDetailFormComponent.Group>
            <ClinicianDetailFormComponent.Group>
              <ClinicianDetailFormComponent.Label>
                Medical Centre
              </ClinicianDetailFormComponent.Label>
              <ClinicianDetailFormComponent.ClinicianInput
                control={control}
                name="medical_centre"
                type="text"
              />
            </ClinicianDetailFormComponent.Group>
            <ClinicianDetailFormComponent.Group>
              <ClinicianDetailFormComponent.Label>
                Work phone number
              </ClinicianDetailFormComponent.Label>
              <ClinicianDetailFormComponent.ClinicianInput
                control={control}
                name="work_phone"
                type="text"
              />
            </ClinicianDetailFormComponent.Group>
            <ClinicianDetailFormComponent.Group>
              <ClinicianDetailFormComponent.Label>
                Personal phone number
              </ClinicianDetailFormComponent.Label>
              <ClinicianDetailFormComponent.ClinicianInput
                control={control}
                name="personal_phone"
                type="text"
              />
            </ClinicianDetailFormComponent.Group>
            <ClinicianDetailFormComponent.Group>
              <ClinicianDetailFormComponent.Label>
                Work email
              </ClinicianDetailFormComponent.Label>
              <ClinicianDetailFormComponent.ClinicianInput
                control={control}
                name="work_email"
                type="text"
              />
            </ClinicianDetailFormComponent.Group>
            <ClinicianDetailFormComponent.Group>
              <ClinicianDetailFormComponent.Label>
                Personal email
              </ClinicianDetailFormComponent.Label>
              <ClinicianDetailFormComponent.ClinicianInput
                control={control}
                name="personal_email"
                type="text"
              />
            </ClinicianDetailFormComponent.Group>
            <ClinicianDetailFormComponent.Group>
              <ClinicianDetailFormComponent.Label>
                Contact Preference
              </ClinicianDetailFormComponent.Label>
              <ClinicianDetailFormComponent.ClinicianInput
                control={control}
                name="contact_preference"
                type="select"
                selectType="contact_choices"
              />
            </ClinicianDetailFormComponent.Group>
            <ClinicianDetailFormComponent.Group>
              <ClinicianDetailFormComponent.Label>
                Consent Status
              </ClinicianDetailFormComponent.Label>
              <ClinicianDetailFormComponent.ClinicianInput
                control={control}
                defaultValue="Pending"
                name="consent_status"
                type="select"
                selectType="clinician_consent_choices"
              />
            </ClinicianDetailFormComponent.Group>
          </ClinicianDetailFormComponent.Row>
          <ClinicianDetailFormComponent.Header>
            Address
          </ClinicianDetailFormComponent.Header>
          <ClinicianDetailFormComponent.Row>
            <ClinicianDetailFormComponent.Group>
              <ClinicianDetailFormComponent.Label>
                Street Name
              </ClinicianDetailFormComponent.Label>
              <ClinicianDetailFormComponent.ClinicianInput
                control={control}
                name="street"
                type="text"
              />
            </ClinicianDetailFormComponent.Group>
            <ClinicianDetailFormComponent.Group>
              <ClinicianDetailFormComponent.Label>
                City
              </ClinicianDetailFormComponent.Label>
              <ClinicianDetailFormComponent.ClinicianInput
                control={control}
                name="city"
                type="text"
              />
            </ClinicianDetailFormComponent.Group>
            <ClinicianDetailFormComponent.Group>
              <ClinicianDetailFormComponent.Label>
                Post Code
              </ClinicianDetailFormComponent.Label>
              <ClinicianDetailFormComponent.ClinicianInput
                control={control}
                name="post_code"
                type="text"
              />
            </ClinicianDetailFormComponent.Group>
            <ClinicianDetailFormComponent.Group>
              <ClinicianDetailFormComponent.Label>
                State
              </ClinicianDetailFormComponent.Label>
              <ClinicianDetailFormComponent.ClinicianInput
                control={control}
                name="state"
                type="select"
                selectType="state"
                labelAsValue
              />
            </ClinicianDetailFormComponent.Group>
          </ClinicianDetailFormComponent.Row>
          <ClinicianDetailFormComponent.Row>
            <ClinicianDetailFormComponent.Button
              type="button"
              txtColor="text-white"
              bgColor="bg-blue-500"
              bgHover="hover:bg-blue-600"
              onClick={() => setModalOpen(true)}
            >
              Add Clinician
            </ClinicianDetailFormComponent.Button>
            <ClinicianDetailFormComponent.Button
              type="button"
              txtColor="text-white"
              bgColor="bg-slate-500"
              bgHover="hover:bg-slate-600"
              onClick={() => reset(updatedData)}
            >
              Reset
            </ClinicianDetailFormComponent.Button>
          </ClinicianDetailFormComponent.Row>
        </ClinicianDetailFormComponent.Content>
      </ClinicianDetailFormComponent>
    </>
  );
};
