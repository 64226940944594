import { Dictionary } from "@reduxjs/toolkit";
import { createApi } from "@reduxjs/toolkit/query/react";
import customFetchBaseQuery from "../../app/customFetchBaseQuery";

interface SelectNameValue {
  value: string;
  label: string;
}

export interface StatusGraph {
  statusGraph: Dictionary<Array<SelectNameValue>>;
  statusAll: {
    /* 
    Add the rest if needed.
    Needed to declare these inorder to access return values from api
    */
    consented: SelectNameValue;
    clinician_nominated: SelectNameValue;
    clinician_defaulted: SelectNameValue;
    clinician_notified: SelectNameValue;
    allocated_prescriber_id: SelectNameValue;
    clinician_rejected: SelectNameValue;
  };
}

// status graph service
export const statusGraphApi = createApi({
  reducerPath: "statusGraphApi",
  baseQuery: customFetchBaseQuery,
  tagTypes: ["StatusGraph"],
  endpoints: (builder) => ({
    getStatusGraph: builder.query<StatusGraph, void>({
      query() {
        return {
          url: "status/graph",
          method: "GET",
        };
      },
    }),
  }),
});

export const { useGetStatusGraphQuery } = statusGraphApi;
