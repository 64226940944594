import { createApi } from "@reduxjs/toolkit/query/react";
import customFetchBaseQuery from "../../app/customFetchBaseQuery";

// Interfaces to represent the structure of the float report response

export interface CardIssued {
  payment_method: string;
  cards_issued: number;
}

export interface PaymentBreakdown {
  total_paid_with_fees: string;
  total_paid_without_fees: string;
  payment_type_label: string;
  no_of_payments: number;
  total_committed: string;
}

export interface TotalSpendByMonth {
  total_paid_with_fees: string;
  total_paid_without_fees: string;
  month_year: string;
}

// This structure is defined as a bar graph component
// requires a certain type format
export interface TotalSpendByMonthTransformed {
  total_paid_with_fees: number;
  total_paid_without_fees: number;
  month_year: string;
}

export interface TotalSpendByPaymentMethod {
  total_paid_with_fees: string;
  total_paid_without_fees: string;
  payment_method: string;
  total_committed: string;
}

export interface PaymentReport {
  cards_issued: CardIssued[];
  payment_breakdown: PaymentBreakdown[];
  total_spend_by_month: TotalSpendByMonth[];
  total_spend_by_payment_method: TotalSpendByPaymentMethod[];
}

// API slice to handle the /float_report endpoint

export const paymentReportApi = createApi({
  reducerPath: "paymentReportApi",
  baseQuery: customFetchBaseQuery,
  tagTypes: ["PaymentReport"],
  endpoints: (builder) => ({
    getPaymentReport: builder.query<PaymentReport, void>({
      query() {
        return {
          url: "float_report",
          method: "GET",
          credentials: "include",
        };
      },
      providesTags: () => ["PaymentReport"],
    }),
  }),
});

// Export the generated hook for use in components

export const { useGetPaymentReportQuery } = paymentReportApi;
