import {
  configureStore,
  ThunkAction,
  Action,
  combineReducers,
} from "@reduxjs/toolkit";
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import { authApi } from "../features/auth/authApi";
import { userApi } from "../features/user/userApi";
import { participantApi } from "../features/participant/participantApi";
import { paymentReportApi } from "../features/payments/PaymentReportApi";
import { clinicianApi } from "../features/clinician/clinicianApi";
import userReducer from "../features/user/userSlice";
import authReducer from "../features/auth/authSlice";
import { infoApi } from "../features/info/infoApi";
import { statusGraphApi } from "../features/participant/statusGraphApi";
import { setupListeners } from "@reduxjs/toolkit/dist/query";

const rootReducer = combineReducers({
  [authApi.reducerPath]: authApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [participantApi.reducerPath]: participantApi.reducer,
  [paymentReportApi.reducerPath]: paymentReportApi.reducer,
  [clinicianApi.reducerPath]: clinicianApi.reducer,
  [infoApi.reducerPath]: infoApi.reducer,
  [statusGraphApi.reducerPath]: statusGraphApi.reducer,
  user: userReducer,
  auth: authReducer,
});

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  blacklist: [
    authApi.reducerPath,
    userApi.reducerPath,
    participantApi.reducerPath,
    paymentReportApi.reducerPath,
    clinicianApi.reducerPath,
    infoApi.reducerPath,
    statusGraphApi.reducerPath,
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat([
      authApi.middleware,
      userApi.middleware,
      participantApi.middleware,
      paymentReportApi.middleware,
      clinicianApi.middleware,
      infoApi.middleware,
      statusGraphApi.middleware,
    ]),
});

setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
