import { createApi } from "@reduxjs/toolkit/query/react";
import customFetchBaseQuery from "../../app/customFetchBaseQuery";
import { setUser } from "./userSlice";

/* 
DRF oddly returns key "pk" as unique identifier for the User
Upon a successful login or via /api/v1/auth/user
*/
export interface User {
  pk: string;
  username: string;
  email: string;
  first_name: string;
  last_name: string;
}

/* Extending User interface to cater for Navigator data type returned from api /navigators */
export interface Navigator extends User {
  id: number;
}

export interface IGenericResponse {
  status: string;
  message: string;
}

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: customFetchBaseQuery,
  tagTypes: ["User", "Navigator"],
  endpoints: (builder) => ({
    getCurrentlyLoggedInUser: builder.query<User, void>({
      query() {
        return {
          url: "user/",
          method: "GET",
          credentials: "include",
        };
      },
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setUser(data));
          // TODO: Delete if using user via cookies implemented
          // localStorage.setItem("user", JSON.stringify(data));
        } catch (error) {
          // Do something else when error is caught
          // Error directly handled by mutations on usage
          // https://redux-toolkit.js.org/rtk-query/usage/mutations#frequently-used-mutation-hook-return-values
        }
      },
    }),
    getNavigators: builder.query<Navigator[], void>({
      query() {
        return {
          url: "navigators",
          method: "GET",
          credentials: "include",
        };
      },
      providesTags(result) {
        return result
          ? [
              ...result.map(({ pk }) => ({
                type: "Navigator" as const,
                id: pk,
              })),
              { type: "Navigator", id: "LIST" },
            ]
          : ["Navigator"];
      },
    }),
  }),
});

export const { useGetCurrentlyLoggedInUserQuery, useGetNavigatorsQuery } =
  userApi;
