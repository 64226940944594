import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

/* Config */

/* Components */
import { Banner, Container } from "../../utils/components/Common";
import { DashboardJumbotron } from "./Dashboard.elements";
import Overview from "../dashboard/Overview";
import Downloads from "../dashboard/Downloads";

/* APIs - Hooks - Utils */
import { useAppSelector } from "../../app/hooks";

const Dashboard = (): JSX.Element => {
  const isLoggedIn = useAppSelector((state) => state.auth.isLoggedIn);
  const user = useAppSelector((state) => state.user.user);

  return (
    <>
      {user && isLoggedIn ? (
        <DashboardJumbotron user={user} isLoggedIn={isLoggedIn}>
          <Tabs>
            <Container padding="px-16 py-6">
              <TabList>
                <Tab>Downloads</Tab>
                <Tab>Overview</Tab>
              </TabList>
            </Container>
            <TabPanel>
              <Downloads user={user} isLoggedIn={isLoggedIn} />
            </TabPanel>
            <TabPanel>
              <Overview />
            </TabPanel>
          </Tabs>
        </DashboardJumbotron>
      ) : (
        <Banner
          heading="Protected Route"
          message="This is a protected route. Authentication required."
        />
      )}
    </>
  );
};

export default Dashboard;
