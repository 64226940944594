import dayjs from "dayjs";

export function getDatetimeString(date = new Date()): string {
  return dayjs(date).format("YYYY-MM-DD HH:mm:ss");
}

export function formatDateTimeString(
  date_time_string: string,
  format: string
): string {
  return dayjs(date_time_string).format(format);
}

export function calculateAge(dob: string): number {
  const birthDate = dayjs(dob);
  const currentDate = dayjs(getDatetimeString());
  let age = currentDate.year() - birthDate.year();
  if (
    currentDate.month() < birthDate.month() ||
    (currentDate.month() === birthDate.month() &&
      currentDate.date() < birthDate.date())
  ) {
    age--;
  }
  return age;
}
