import { RootState } from "./store";
import {
  retry,
  fetchBaseQuery,
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/dist/query";
import { toast, Flip } from "react-toastify";
import { Mutex } from "async-mutex";
import {
  refreshCredentials,
  unsetCredentials,
} from "../features/auth/authSlice";
import config from "./config.json";
import { unsetUser } from "../features/user/userSlice";

/* Implement lock around re-authorization routine */
const reAuthLock = new Mutex();

const staggeredBaseQueryWithBailOut = retry(
  async (args: string | FetchArgs, api, extraOptions) => {
    const result = await fetchBaseQuery({
      baseUrl: config.urls.base.url,
      prepareHeaders: (headers, { getState }) => {
        headers.set("Content-type", "application/json");
        const token = (getState() as RootState).auth.access_token;
        if (token) {
          headers.set("Authorization", `Bearer ${token}`);
        }
        return headers;
      },
    })(args, api, extraOptions);

    /* 
    If 401 is returned, fail the retry providing the result.
    This bails out the set retry attempts and begin re-authorization flow.
    */
    if (result.error?.status === 401) {
      retry.fail(result.error);
    }

    return result;
  },
  {
    maxRetries: 0,
  }
);

const customFetchBaseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  /* 
  A lock around re-auth attempts to prevent simultaneous re-auth attempts,
  those that are locked out will simply retry as the re-auth should have happened whilst waiting
  */
  await reAuthLock.waitForUnlock();

  /* Attempt to perform query / api call */
  let result = await staggeredBaseQueryWithBailOut(args, api, extraOptions);

  /* Catching `Untauthorized` error response */
  if (result.error && result.error.status === 401) {
    /* Proceed with re-authorization if not locked */
    if (!reAuthLock.isLocked()) {
      /* Aquire Mutex Lock */
      const release = await reAuthLock.acquire();

      /* Attempt to refresh the token */
      try {
        const refreshResult = await staggeredBaseQueryWithBailOut(
          {
            url: "auth/token/refresh/",
            method: "POST",
          },
          api,
          extraOptions
        );

        /* OK, we've got a response. Perform a refresh on credentials */
        if (refreshResult.data) {
          api.dispatch(refreshCredentials(refreshResult.data));
          result = await staggeredBaseQueryWithBailOut(args, api, extraOptions);
        } else {
          /* Bail out, we can't refresh credentials */
          api.dispatch(unsetCredentials());
          api.dispatch(unsetUser());
          toast.warning("Logged out due to inactivity..", {
            autoClose: 5000,
            position: "top-center",
            transition: Flip,
          });
        }
      } finally {
        /* Release re-authorization routine lock */
        release();
      }
    } else {
      /* Another process is in the re-authorization routine the token so wait for it to finish then retry. */
      await reAuthLock.waitForUnlock();
      result = await staggeredBaseQueryWithBailOut(args, api, extraOptions);
    }
  }
  return result;
};

export default customFetchBaseQuery;
