import React from "react";

/* Config */

/* Components */
import { Banner, Container } from "../../utils/components/Common";

/* APIs - Utils */

const Overview = (): JSX.Element => {
  return (
    <>
      <Container padding="px-16 py-6">
        <Banner
          heading="Overview"
          message="🚧 This is a place holder for the Overview section of the Dashboard. 🚧"
        />
      </Container>
    </>
  );
};

export default Overview;
