import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

/* Components */
import { ClinicianDetailForm } from "./ClinicianDetailForm";
import {
  NavigateBackButton,
  LoadingScreen,
  FloatingContainer,
} from "../../utils/components/Common";

/* APIs - Utils */
import { useGetClinicianQuery } from "./clinicianApi";
import { toastQueryError } from "../../utils/toasts";

const ClinicianDetail = (): JSX.Element => {
  const { pk } = useParams();
  const [processing, setProcessing] = useState(true);
  const { data, isFetching, isLoading, isError, error } =
    useGetClinicianQuery(pk);

  useEffect(() => {
    if (isError && error) {
      toastQueryError(error);
    }
  }, [isError]);

  useEffect(() => {
    if (isFetching || isLoading) {
      setProcessing(true);
    } else {
      setProcessing(false);
    }
  }, [isLoading, isFetching]);

  return (
    <>
      {processing ? <LoadingScreen /> : null}
      {data ? (
        <>
          <FloatingContainer placement="bot-left">
            <NavigateBackButton>⬅️ Go Back</NavigateBackButton>
          </FloatingContainer>
          <ClinicianDetailForm data={data} />
        </>
      ) : null}
    </>
  );
};

export default ClinicianDetail;
