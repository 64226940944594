import React from "react";
import { User } from "./userApi";

type DashboardJumbotronProps = {
  user: User;
  isLoggedIn: boolean;
  children?: React.ReactNode;
};

export const DashboardJumbotron = ({
  user,
  isLoggedIn,
  children,
}: DashboardJumbotronProps): JSX.Element => {
  return (
    <>
      <div className="max-w-screen-xl px-4 py-8 mx-auto sm:px-6 lg:px-8">
        {isLoggedIn && user ? (
          <div className="mt-8">
            <h1 className="text-2xl font-bold text-gray-900 sm:text-3xl">
              Welcome to the Dashboard, {user.first_name}! 🎉
            </h1>
          </div>
        ) : null}
      </div>
      {children}
    </>
  );
};
