import React, { useState, useEffect } from "react";

/* Config */
import config from "../../app/config.json";

/* Components */
import { HeroSection, FormButton } from "../../utils/components/Common";

/* APIs - Utils*/
import { useAppSelector } from "../../app/hooks";
import { useMiddlewareLoginQuery } from "../auth/authApi";

const Landing = (): JSX.Element => {
  const user = useAppSelector((state) => state.user.user);
  const [csrfMiddlewareToken, setCsrfMiddlewareToken] = useState("");

  const parser = new DOMParser();
  const middlewareLogin = useMiddlewareLoginQuery();

  useEffect(() => {
    if (middlewareLogin.isSuccess) {
      const loginPageInputElements = parser
        .parseFromString(middlewareLogin.data, "text/html")
        .getElementsByTagName("input");
      setCsrfMiddlewareToken(loginPageInputElements[0].value);
    }
  }, [middlewareLogin.isSuccess]);

  return (
    <>
      <HeroSection heading="MotivateC Navigator Portal">
        {!user ? (
          <form action={`${config.urls.base.url}auth/okta/url/`} method="POST">
            <input
              type="hidden"
              name="csrfmiddlewaretoken"
              value={csrfMiddlewareToken}
            />
            <FormButton
              txtColor="text-white"
              bgColor="bg-blue-500"
              bgHover="hover:bg-blue-600"
              type="submit"
            >
              Login
            </FormButton>
          </form>
        ) : null}
      </HeroSection>
    </>
  );
};

export default Landing;
