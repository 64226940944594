import React from "react";
import { useController, UseControllerProps } from "react-hook-form";
import classNames from "classnames";
import { formatDateTimeString, calculateAge } from "../datetime";

/* Config */
import config from "../../app/config.json";

/* Components */
import {
  FormButton,
  Modal,
  ControlledSelect,
  ControlledCheckbox,
} from "./Common";

/* APIs - Hooks - Utils */
import { Navigator } from "../../features/user/userApi";
import {
  Clinician,
  isClinicianType,
} from "../../features/clinician/clinicianApi";
import { Participant } from "../../features/participant/participantApi";
import { StatusGraph } from "../../features/participant/statusGraphApi";

function isDisabledOption(obj: object) {
  return typeof obj === "object" && obj !== null && "disabled" in obj;
}

type DetailFormComponentProps = {
  children: React.ReactNode;
};

const DetailFormComponent = ({
  children,
}: DetailFormComponentProps): JSX.Element => {
  return (
    <>
      <div className="bg-gray-100 mx-auto max-w-6xl my-6 py-20 px-12 lg:px-24 shadow-xl mb-24">
        {children}
      </div>
    </>
  );
};

type DetailFormSectionProps = {
  open?: boolean;
  sticky?: boolean;
  children: React.ReactNode;
};

const DetailFormSection = ({
  open,
  sticky,
  children,
}: DetailFormSectionProps): JSX.Element => {
  const sectionClassName = classNames({
    "px-4 py-2 cursor-pointer hover:bg-slate-100": true,
    "sticky top-0 bg-slate-100": sticky,
  });
  return (
    <>
      <details open={open} className={sectionClassName}>
        {children}
      </details>
    </>
  );
};
type DetailFormHeaderProps = {
  sub?: boolean;
  children: React.ReactNode;
};

const DetailFormHeader = ({
  children,
  sub,
}: DetailFormHeaderProps): JSX.Element => {
  const headerSize = sub ? "text-xl" : "text-2xl";
  return (
    <>
      <summary className={`py-4 my-4 text-slate-600 ${headerSize}`}>
        {children}
      </summary>
    </>
  );
};

type DetailFormContentProps = {
  onSubmit?: () => void;
  children: React.ReactNode;
};

const DetailFormContent = ({
  onSubmit,
  children,
}: DetailFormContentProps): JSX.Element => {
  return (
    <>
      <form
        onSubmit={onSubmit}
        className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col"
      >
        {children}
      </form>
    </>
  );
};

type DetailFormRowProps = {
  children: React.ReactNode;
  bordered?: boolean;
};

const DetailFormRow = ({
  children,
  bordered,
}: DetailFormRowProps): JSX.Element => {
  const formRowClassName = classNames({
    "m-2 flex-wrap md:flex": true,
    "border-dashed border-2 border-slate-300 hover:border-green-400 hover:border-solid p-4 w-full":
      bordered,
  });
  return (
    <>
      <div className={formRowClassName}>{children}</div>
    </>
  );
};

type DetailFormGroupProps = {
  children: React.ReactNode;
  inline?: boolean;
};

const DetailFormGroup = ({
  children,
  inline = false,
}: DetailFormGroupProps): JSX.Element => {
  const groupWidth = inline ? "flex md:w-full" : "md:w-1/2";
  return (
    <>
      <div className={`${groupWidth} px-3 mb-6 md:mb-0`}>{children}</div>
    </>
  );
};

type DetailFormLabelProps = {
  htmlFor?: string;
  children?: React.ReactNode;
  inline?: boolean;
};

const DetailFormLabel = ({
  htmlFor,
  children,
  inline = false,
}: DetailFormLabelProps): JSX.Element => {
  const labelClassName = classNames({
    "py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider":
      true,
    "md:w-5/6": inline,
  });
  return (
    <>
      <label htmlFor={htmlFor} className={labelClassName}>
        {children}
      </label>
    </>
  );
};

interface ClinicianDetailFormInputProps extends UseControllerProps<Clinician> {
  type?: "text" | "date" | "datetime-local" | "select";
  selectType?:
    | "yn"
    | "gender"
    | "clinician_consent_choices"
    | "contact_choices"
    | "payment"
    | "hcv_test_results"
    | "svr_test_results"
    | "state";
  labelAsValue?: boolean;
  disabled?: boolean;
}

const ClinicianDetailFormInput = ({
  name,
  rules,
  shouldUnregister,
  defaultValue,
  control,
  type,
  selectType,
  labelAsValue,
  disabled,
}: ClinicianDetailFormInputProps): JSX.Element => {
  const { field, fieldState } = useController({
    name,
    rules,
    shouldUnregister,
    defaultValue,
    control,
  });
  const inputClassName = classNames({
    "mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500":
      true,
    "border-amber-500 border-2": fieldState.isDirty,
    "border-pink-500 text-pink-600 focus:border-pink-500 focus:ring-pink-500":
      fieldState.error,
    "disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none":
      disabled,
  });

  return (
    <>
      {type === "select" && selectType ? (
        <select
          {...field}
          disabled={disabled}
          placeholder={name.replaceAll("_", " ").toUpperCase()}
          className={inputClassName}
        >
          <option value="">Select</option>
          {config.form.select[`${selectType}`].map((option, i) => (
            <option key={i} value={labelAsValue ? option.label : option.value}>
              {option.label}
            </option>
          ))}
        </select>
      ) : (
        <input
          {...field}
          type={type}
          disabled={disabled}
          placeholder={name.replaceAll("_", " ").toUpperCase()}
          className={inputClassName}
        />
      )}

      {fieldState.error ? (
        <p className="mt-2 text-pink-600 text-sm">{fieldState.error.message}</p>
      ) : null}
    </>
  );
};

interface ParticipantDetailFormInputProps
  extends UseControllerProps<Participant> {
  type?:
    | "text"
    | "date"
    | "datetime-local"
    | "select"
    | "textarea"
    | "checkbox";
  selectType?:
    | "yn"
    | "yes_no_na"
    | "ynLiteral"
    | "gender"
    | "payment"
    | "state"
    | "stateTransitions"
    | "hcv_test_results"
    | "svr_test_results"
    | "daa_verified"
    | "pending_yes_no"
    | "daaf_days_tmt_missed"
    | "trial_discovery"
    | "contact_type";
  dynamicSelectType?: boolean;
  dynamicSelections?: Navigator[] | Clinician[];
  nameValueSelections?: StatusGraph;
  disabled?: boolean;
  fullWidth?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

const ParticipantDetailFormInput = ({
  name,
  rules,
  shouldUnregister,
  defaultValue,
  control,
  type,
  selectType,
  dynamicSelectType,
  dynamicSelections,
  nameValueSelections,
  disabled,
  fullWidth = true,
  onChange,
}: ParticipantDetailFormInputProps): JSX.Element => {
  const { field, fieldState } = useController({
    name,
    rules,
    shouldUnregister,
    defaultValue,
    control,
  });
  const inputClassName = classNames({
    "mt-1 block px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500 flex-1":
      true,
    "w-full": fullWidth,
    "border-amber-500 border-2": fieldState.isDirty,
    "border-pink-500 text-pink-600 focus:border-pink-500 focus:ring-pink-500":
      fieldState.error,
    "disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none":
      disabled,
  });

  const bespokeOnChange = onChange
    ? (e: React.ChangeEvent<HTMLSelectElement>) => {
        field.onChange(e);
        onChange(e);
      }
    : field.onChange;

  return (
    <>
      {type === "select" && selectType ? (
        <select
          {...field}
          disabled={disabled}
          placeholder={name.replaceAll("_", " ").toUpperCase()}
          className={inputClassName}
          onChange={bespokeOnChange}
        >
          <option value="">Select</option>
          {config.form.select[`${selectType}`].map((option, i) => (
            <option
              key={i}
              value={option.value}
              disabled={isDisabledOption(option)}
            >
              {option.label}
            </option>
          ))}
        </select>
      ) : type === "select" && dynamicSelectType && dynamicSelections ? (
        <select
          {...field}
          disabled={disabled}
          placeholder={name.replaceAll("_", " ").toUpperCase()}
          className={inputClassName}
          onChange={bespokeOnChange}
        >
          <option value="">Select</option>
          {dynamicSelections.map((option, i) => (
            <option key={i} value={option.id}>
              {option.first_name} {option.last_name}{" "}
              {isClinicianType(option)
                ? `- (🏥 ${option.medical_centre}, ${option.num_allocated_participants} allocated participants)`
                : null}
            </option>
          ))}
        </select>
      ) : type === "select" && dynamicSelectType && nameValueSelections ? (
        <select
          {...field}
          disabled={disabled}
          placeholder={name.replaceAll("_", " ").toUpperCase()}
          className={inputClassName}
          onChange={bespokeOnChange}
        >
          <option value="">Select</option>
          {nameValueSelections.statusGraph[`${field.value}`]?.map(
            (nv_pair, i) => (
              <option key={i} value={nv_pair.value}>
                {nv_pair.label}
              </option>
            )
          )}
        </select>
      ) : type === "textarea" ? (
        <textarea
          {...field}
          disabled={disabled}
          placeholder={name.replaceAll("_", " ").toUpperCase()}
          className={inputClassName}
        />
      ) : type === "checkbox" ? (
        <input
          {...field}
          type="checkbox"
          disabled={disabled}
          className={inputClassName}
          checked={field.value === "True"}
          onChange={(e) => field.onChange(e.target.checked ? "True" : "False")}
        />
      ) : (
        <input
          {...field}
          type={type}
          disabled={disabled}
          placeholder={name.replaceAll("_", " ").toUpperCase()}
          className={inputClassName}
        />
      )}

      {fieldState.error ? (
        <p className="mt-2 text-pink-600 text-sm">{fieldState.error.message}</p>
      ) : null}
    </>
  );
};

type ExpiryMessageComponentProps = {
  has_lapsed?: string;
  project_cutoff_date_time?: string | undefined;
};

const ExpiryMessageComponent: React.FC<ExpiryMessageComponentProps> = ({
  has_lapsed,
  project_cutoff_date_time,
}) => {
  if (has_lapsed === "True") {
    const cutoffDateTime = project_cutoff_date_time ?? "N/A";
    return (
      <p className="text-sm font-bold text-yellow-500 dark:text-yellow-400">
        {`12 week eligibility window expired on ${new Date(
          cutoffDateTime
        ).toLocaleDateString("en-AU", {
          day: "numeric",
          month: "numeric",
          year: "numeric",
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        })}`}
      </p>
    );
  }

  return null;
};

type CtaStatusProps = {
  children?: React.ReactNode;
  participant?: Participant;
};

const CtaStatus = ({ children, participant }: CtaStatusProps): JSX.Element => {
  let stop_message = "";
  if (participant?.stop_message_date_time) {
    stop_message = `STOP sent at ${formatDateTimeString(
      `${participant?.stop_message_date_time}`,
      "h:mmA, D MMM YYYY"
    )}`;
  }
  return (
    <>
      <div className="sticky z-50 top-0 mx-auto p-4 bg-white border border-slate-100 rounded-md drop-shadow-xl lg:max-w-6xl dark:bg-slate-700 dark:border-slate-600">
        <div className="flex justify-between mb-3 mr-4 md:items-center md:flex-row md:mb-0">
          <div className="self-center mb-2 border-gray-200 md:pr-4 md:mr-4 md:border-r md:mb-0 dark:border-gray-600 w-1/6">
            <div className="text-xl font-semibold dark:text-white mb-2">
              {`[${participant?.record_id}] ${participant?.first_name} ${participant?.last_name}`}
            </div>
            {participant?.date_of_birth ? (
              <p className="text-sm font-normal text-gray-500 dark:text-gray-400">
                {`${calculateAge(participant.date_of_birth)}`}
              </p>
            ) : null}
            <p className="text-sm font-normal text-gray-500 dark:text-gray-400">
              {`${participant?.mobile}`}
            </p>
            <p className="text-sm font-normal text-gray-500 dark:text-gray-400">
              {`${participant?.participant_email_address}`}
            </p>
            <p className="text-sm font-bold text-amber-400 dark:text-amber-300">
              {`${stop_message}`}
            </p>
            <ExpiryMessageComponent
              has_lapsed={participant?.has_lapsed}
              project_cutoff_date_time={participant?.project_cutoff_date_time}
            />
          </div>
          <div className="flex">{children}</div>
        </div>
      </div>
    </>
  );
};

/* Under Dev; select input className 
<select className="w-full bg-gray-200 border border-gray-200 text-black text-xs py-3 px-4 pr-8 mb-3 rounded" id="location"></select>
*/

DetailFormComponent.Content = DetailFormContent;
DetailFormComponent.Section = DetailFormSection;
DetailFormComponent.Header = DetailFormHeader;
DetailFormComponent.Row = DetailFormRow;
DetailFormComponent.Group = DetailFormGroup;
DetailFormComponent.Label = DetailFormLabel;
DetailFormComponent.ClinicianInput = ClinicianDetailFormInput;
DetailFormComponent.ParticipantInput = ParticipantDetailFormInput;
DetailFormComponent.ControlledSelect = ControlledSelect;
DetailFormComponent.ControlledCheckbox = ControlledCheckbox;
DetailFormComponent.Button = FormButton;
DetailFormComponent.Modal = Modal;
DetailFormComponent.CtaStatus = CtaStatus;

export default DetailFormComponent;
