import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

/* Config */
import config from "../../app/config.json";

/* Components */
import DetailFormComponent from "../../utils/components/DetailForm";
import {
  Banner,
  FloatingContainer,
  LinkButton,
  LoadingScreen,
} from "../../utils/components/Common";

/* APIs - Utils */
import { Navigator } from "../user/userApi";
import { Clinician } from "../clinician/clinicianApi";
import { Participant, useUpdateParticipantMutation } from "./participantApi";
import { useGetCliniciansQuery } from "../clinician/clinicianApi";
import { toastQueryError, toastQuerySuccess } from "../../utils/toasts";
import { getDatetimeString } from "../../utils/datetime";
import { StatusGraph } from "./statusGraphApi";
import { useAppSelector } from "../../app/hooks";
import participantSchema from "./participantSchema";
import { usePrompt } from "../../utils/hooks";
import { ClinicianAddForm } from "../clinician/ClinicianAddForm";

interface StringToBool {
  [key: string]: boolean;
}

type EligibleFields =
  | "self_report_hep_c"
  | "on_current_hep_c_treatment"
  | "age_18_or_older"
  | "has_medicare_card"
  | "resident_in_australia"
  | "previously_enrolled"
  | "able_complete_follow_up"
  | "contraindication_treatment"
  | "able_to_provide_consent";

type DetailFormProps = {
  navigators?: Navigator[];
  statusGraph?: StatusGraph;
  participant?: Participant;
};

export const ParticipantDetailForm = ({
  navigators,
  statusGraph,
  participant,
}: DetailFormProps): JSX.Element => {
  const user = useAppSelector((state) => state.user.user);
  const clinicians = useGetCliniciansQuery(undefined, {
    selectFromResult: ({ data, isSuccess, isFetching }) => ({
      isSuccess,
      isFetching,
      data: data?.filter((clinician) => clinician.consent_status !== "OptOut"),
    }),
  });
  const [latestParticipantData, setLatestParticipant] = useState(participant);
  const [updateParticipant, updateParticipantResult] =
    useUpdateParticipantMutation();
  const [confirmDataModalOpen, setConfirmDataModalOpen] = useState(false);
  const [addClinicianModalOpen, setAddClinicianModalOpen] = useState(false);
  const [addedClinician, setAddedClinician] = useState<Clinician | undefined>(
    undefined
  );
  const [eligible, setEligible] = useState("");
  const [trialDiscoveryOtherShow, setTrialDiscoveryOtherShow] = useState(false);
  const [nominateClinicianDisabled, setNominateClinicianDisabled] =
    useState(false);

  const {
    handleSubmit,
    control,
    reset,
    formState: { isDirty, dirtyFields },
    getValues,
    setValue,
    watch,
  } = useForm<Participant>({
    resolver: yupResolver(participantSchema),
    defaultValues: latestParticipantData,
    mode: "onChange",
  });

  const onSubmit = (modifiedParticipant: Participant) => {
    setConfirmDataModalOpen(false);
    /* Attempt to read(unwrap) returned data from PUT api call */
    updateParticipant(modifiedParticipant)
      .unwrap()
      .then((returnedData) => {
        reset(returnedData);
        setLatestParticipant(returnedData);
        toastQuerySuccess("Successfully updated participant..");
      })
      .catch((returnedError) => toastQueryError(returnedError));
    // Do something else when error is caught
    // Error directly handled by mutations on usage
    // https://redux-toolkit.js.org/rtk-query/usage/mutations#frequently-used-mutation-hook-return-values
  };

  const consentFields = watch([
    "has_read_pi_sheet",
    "understands_procedures_risk",
    "queries_answered",
    "freely_agrees_to_participate",
    "aware_of_project_withdrawal",
    "consents_to_pi_use",
  ]);

  // Add text area if trial discovery selection set to `other`
  const onTrialDiscoveryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value == config.form.select.trial_discovery[10].value) {
      setTrialDiscoveryOtherShow(true);
    } else {
      setTrialDiscoveryOtherShow(false);
    }
  };

  const determineEligibility = (
    e?: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    // eligible if all yes except for on_current_hep_c_treatment, previously_enrolled, contraindication_treatment
    const questions_answ_is_no = [
      "on_current_hep_c_treatment",
      "previously_enrolled",
      "contraindication_treatment",
    ];
    const getEligibleValue = (fieldName: string) => {
      return questions_answ_is_no.indexOf(fieldName) > -1 ? "0" : "1";
    };
    const isFieldEligible = (fieldName: EligibleFields) => {
      return getValues(fieldName) === getEligibleValue(fieldName);
    };

    const eligibleFlags: StringToBool = {
      self_report_hep_c: isFieldEligible("self_report_hep_c"),
      on_current_hep_c_treatment: isFieldEligible("on_current_hep_c_treatment"),
      age_18_or_older: isFieldEligible("age_18_or_older"),
      has_medicare_card: isFieldEligible("has_medicare_card"),
      resident_in_australia: isFieldEligible("resident_in_australia"),
      previously_enrolled: isFieldEligible("previously_enrolled"),
      able_complete_follow_up: isFieldEligible("able_complete_follow_up"),
      contraindication_treatment: isFieldEligible("contraindication_treatment"),
      able_to_provide_consent: isFieldEligible("able_to_provide_consent"),
    };
    if (e !== undefined) {
      eligibleFlags[e.target.name] =
        e.target.value === getEligibleValue(e.target.name);
    }

    let isEligible = true;
    for (const key in eligibleFlags) {
      isEligible = isEligible && eligibleFlags[key];
    }

    setEligible(isEligible ? "Eligible" : "Ineligible");
    if (e !== undefined) {
      // update display if changing wihtin this section only
      if (isEligible) {
        setValue("confirm_eligible_date_time", getDatetimeString(), {
          shouldDirty: true,
          shouldValidate: true,
        });
      } else {
        setValue("confirm_eligible_date_time", "", {
          shouldDirty: true,
          shouldValidate: true,
        });
      }
    }
  };

  // update eligiblity on update
  const onEligibleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    determineEligibility(e);
  };

  /* When `Do you have a GP?` dropdown changes */
  const onClinicianNominateChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    if (clinicians && statusGraph) {
      if (e.target.value == "No") {
        /* NO */
        setValue("requested_prescriber_id", "", {
          shouldDirty: true,
          shouldValidate: true,
        });
        setNominateClinicianDisabled(true);
      }
      if (e.target.value == "Yes") {
        /* YES */
        setNominateClinicianDisabled(false);
      }
    }
  };

  const showStatusTransition = (): JSX.Element => {
    return (
      <>
        <DetailFormComponent.Row bordered>
          <DetailFormComponent.Group>
            <DetailFormComponent.Label>Reset Status</DetailFormComponent.Label>
            <DetailFormComponent.ParticipantInput
              control={control}
              name="status"
              type="select"
              selectType="stateTransitions"
            />
          </DetailFormComponent.Group>
          <DetailFormComponent.Group>
            <DetailFormComponent.Label>
              Transition Status
            </DetailFormComponent.Label>
            <DetailFormComponent.ParticipantInput
              control={control}
              name="status"
              type="select"
              nameValueSelections={statusGraph}
              dynamicSelectType
            />
          </DetailFormComponent.Group>
        </DetailFormComponent.Row>
      </>
    );
  };
  const filterClinicians = (
    clinicians?: Clinician[],
    defaultOnly?: boolean
  ): Clinician[] | undefined => {
    const result = clinicians?.filter((obj) => {
      if (defaultOnly) {
        return obj.consent_status === "Default";
      } else {
        return obj.consent_status !== "Default";
      }
    });
    return result;
  };

  /* Track consentField changes */
  useEffect(() => {
    if (consentFields.every((item) => item === "1")) {
      if (
        !getValues("consent_date_time") &&
        !getValues("consented_by") &&
        !getValues("navigator_confirm_consent")
      ) {
        setValue("navigator_confirm_consent", "1", {
          shouldDirty: true,
          shouldValidate: true,
        });
        setValue("consent_date_time", getDatetimeString(), {
          shouldDirty: true,
          shouldValidate: true,
        });
        if (user) {
          setValue("consented_by", user?.pk, {
            shouldDirty: true,
            shouldValidate: true,
          });
        }
      }
    }
  }, [consentFields]);

  /* Track fields upon page reload */
  useEffect(() => {
    if (getValues("did_request_prescriber") == "Yes") {
      setNominateClinicianDisabled(false);
    } else {
      setNominateClinicianDisabled(true);
    }
    if (addedClinician) {
      setValue("requested_prescriber_id", addedClinician.id, {
        shouldDirty: true,
        shouldValidate: true,
      });
    }
  }, [clinicians.isFetching]);

  usePrompt({
    message:
      "Unsaved changes may be lost.\nAre you sure you want to leave the page?",
    when: isDirty,
  });

  return (
    <>
      {updateParticipantResult.isLoading ? <LoadingScreen blackOut /> : null}
      <DetailFormComponent.CtaStatus participant={participant}>
        {showStatusTransition()}
        <DetailFormComponent.Label>Notes Box</DetailFormComponent.Label>
        <DetailFormComponent.Group inline>
          <DetailFormComponent.ParticipantInput
            control={control}
            name="notes_box"
            type="textarea"
          />
        </DetailFormComponent.Group>
      </DetailFormComponent.CtaStatus>
      <DetailFormComponent>
        <DetailFormComponent.Content onSubmit={handleSubmit(onSubmit)}>
          {confirmDataModalOpen ? (
            <DetailFormComponent.Modal
              setModalOpen={setConfirmDataModalOpen}
              header="Please verify changes to fields"
              body={`${JSON.stringify(
                Object.keys(dirtyFields).map((key) =>
                  key === "sex" ? "gender" : key
                ),
                null,
                4
              )}`}
            >
              <DetailFormComponent.Button
                type="button"
                txtColor="text-red-500"
                bgColor="bg-transparent"
                onClick={() => setConfirmDataModalOpen(false)}
              >
                CLOSE
              </DetailFormComponent.Button>
              <DetailFormComponent.Button
                type="submit"
                txtColor="text-white"
                bgColor="bg-emerald-500"
                bgHover="hover:bg-emerald-600"
              >
                Save Changes
              </DetailFormComponent.Button>
            </DetailFormComponent.Modal>
          ) : null}

          {addClinicianModalOpen ? (
            <DetailFormComponent.Modal
              setModalOpen={setAddClinicianModalOpen}
              header="Add clinician"
              body="Add clinicians to the project."
            >
              <ClinicianAddForm
                setAddClinicianModalOpen={setAddClinicianModalOpen}
                setAddedClinician={setAddedClinician}
              />
            </DetailFormComponent.Modal>
          ) : null}

          {/* 
          Section: (START) Registration 
          */}
          <DetailFormComponent.Section>
            <DetailFormComponent.Header>
              Registration
            </DetailFormComponent.Header>
            <DetailFormComponent.Row>
              <DetailFormComponent.Group>
                <DetailFormComponent.Label>Mobile</DetailFormComponent.Label>
                <DetailFormComponent.ParticipantInput
                  control={control}
                  name="mobile"
                  type="text"
                  disabled
                />
              </DetailFormComponent.Group>
              <DetailFormComponent.Group>
                <DetailFormComponent.Label>
                  Randomisation Date
                </DetailFormComponent.Label>
                <DetailFormComponent.ParticipantInput
                  control={control}
                  name="randomised_date_time"
                  type="datetime-local"
                  disabled
                />
              </DetailFormComponent.Group>
              <DetailFormComponent.Group>
                <DetailFormComponent.Label>
                  Randomised Allocation
                </DetailFormComponent.Label>
                <DetailFormComponent.ParticipantInput
                  control={control}
                  name="randomised_participant"
                  type="text"
                  disabled
                />
              </DetailFormComponent.Group>
              <DetailFormComponent.Group>
                <DetailFormComponent.Label>
                  Registration Date
                </DetailFormComponent.Label>
                <DetailFormComponent.ParticipantInput
                  control={control}
                  name="register_date_time"
                  type="datetime-local"
                  disabled
                />
              </DetailFormComponent.Group>
              <DetailFormComponent.Group>
                <DetailFormComponent.Label>
                  Allocated Navigator
                </DetailFormComponent.Label>
                <DetailFormComponent.ParticipantInput
                  control={control}
                  name="allocated_navigator"
                  type="select"
                  dynamicSelections={navigators}
                  dynamicSelectType
                />
              </DetailFormComponent.Group>
              <DetailFormComponent.Group>
                <DetailFormComponent.Label>
                  Cutoff Date
                </DetailFormComponent.Label>
                <DetailFormComponent.ParticipantInput
                  control={control}
                  name="project_cutoff_date_time"
                  type="datetime-local"
                  disabled
                />
              </DetailFormComponent.Group>
            </DetailFormComponent.Row>
          </DetailFormComponent.Section>
          {/* 
          Section: (END) Registration 
          */}

          {/* 
          Section: (START) Participant Information 
          */}
          <DetailFormComponent.Section>
            <DetailFormComponent.Row>
              <DetailFormComponent.Group>
                <DetailFormComponent.Label>
                  Preferred Name
                </DetailFormComponent.Label>
                <DetailFormComponent.ParticipantInput
                  control={control}
                  name="preferred_name"
                  type="text"
                />
              </DetailFormComponent.Group>
              <DetailFormComponent.Group>
                <DetailFormComponent.Label>State</DetailFormComponent.Label>
                <DetailFormComponent.ParticipantInput
                  control={control}
                  name="mailing_state"
                  type="select"
                  selectType="state"
                />
              </DetailFormComponent.Group>
              <DetailFormComponent.Group>
                <DetailFormComponent.Label>
                  Email Address
                </DetailFormComponent.Label>
                <DetailFormComponent.ParticipantInput
                  control={control}
                  name="participant_email_address"
                  type="text"
                />
              </DetailFormComponent.Group>
              <DetailFormComponent.Group>
                <DetailFormComponent.Label>
                  Where did the participant find out about the trial?
                </DetailFormComponent.Label>
                <DetailFormComponent.ParticipantInput
                  control={control}
                  name="trial_discovery"
                  type="select"
                  selectType="trial_discovery"
                  onChange={onTrialDiscoveryChange}
                />
              </DetailFormComponent.Group>
              {trialDiscoveryOtherShow ||
              getValues("trial_discovery_other") != "" ? (
                <DetailFormComponent.Group>
                  <DetailFormComponent.Label>
                    Please specify where the participant found out about the
                    trial.
                  </DetailFormComponent.Label>
                  <DetailFormComponent.ParticipantInput
                    control={control}
                    name="trial_discovery_other"
                    type="text"
                  />
                </DetailFormComponent.Group>
              ) : null}
            </DetailFormComponent.Row>

            <DetailFormComponent.Header>
              Participant Information
            </DetailFormComponent.Header>
            <Banner
              heading="Unique Constraint Fields"
              message="If any of First Name, Last Name, DOB fields are filled in, they all must be filled in"
            />
            <DetailFormComponent.Row>
              <DetailFormComponent.Group>
                <DetailFormComponent.Label>
                  First Name
                </DetailFormComponent.Label>
                <DetailFormComponent.ParticipantInput
                  control={control}
                  name="first_name"
                  type="text"
                />
              </DetailFormComponent.Group>
              <DetailFormComponent.Group>
                <DetailFormComponent.Label>Last Name</DetailFormComponent.Label>
                <DetailFormComponent.ParticipantInput
                  control={control}
                  name="last_name"
                  type="text"
                />
              </DetailFormComponent.Group>
              <DetailFormComponent.Group>
                <DetailFormComponent.Label>
                  Date of Birth
                </DetailFormComponent.Label>
                <DetailFormComponent.ParticipantInput
                  control={control}
                  name="date_of_birth"
                  type="date"
                />
              </DetailFormComponent.Group>
              <DetailFormComponent.Group>
                <DetailFormComponent.Label>Gender</DetailFormComponent.Label>
                <DetailFormComponent.ParticipantInput
                  control={control}
                  name="sex"
                  type="select"
                  selectType="gender"
                />
              </DetailFormComponent.Group>
            </DetailFormComponent.Row>
            <DetailFormComponent.Row>
              <DetailFormComponent.Group>
                <DetailFormComponent.Label>
                  Preferred Mobile
                </DetailFormComponent.Label>
                <DetailFormComponent.ParticipantInput
                  control={control}
                  name="preferred_mobile"
                  type="text"
                />
              </DetailFormComponent.Group>
            </DetailFormComponent.Row>
          </DetailFormComponent.Section>
          {/* 
          Section: (END) Participant Information 
          */}

          {/*
          Section: (START) Scheduling
          */}
          <DetailFormComponent.Section>
            <DetailFormComponent.Header>Scheduling</DetailFormComponent.Header>
            <DetailFormComponent.Row>
              <DetailFormComponent.Group>
                <DetailFormComponent.Label>
                  Next Contact Date
                </DetailFormComponent.Label>
                <DetailFormComponent.ParticipantInput
                  control={control}
                  name="next_contact_date"
                  type="date"
                  disabled
                />
              </DetailFormComponent.Group>
            </DetailFormComponent.Row>
            <DetailFormComponent.Row>
              <DetailFormComponent.Group>
                <DetailFormComponent.Label>
                  HCV Test Reminder Date
                </DetailFormComponent.Label>
                <DetailFormComponent.ParticipantInput
                  control={control}
                  name="hcv_test_reminder_date"
                  type="date"
                  disabled
                />
              </DetailFormComponent.Group>
              <DetailFormComponent.Group>
                <DetailFormComponent.Label>
                  DAA Reminder Date
                </DetailFormComponent.Label>
                <DetailFormComponent.ParticipantInput
                  control={control}
                  name="daa_reminder_date"
                  type="date"
                  disabled
                />
              </DetailFormComponent.Group>
              <DetailFormComponent.Group>
                <DetailFormComponent.Label>
                  Mid Treatment Checkin Date
                </DetailFormComponent.Label>
                <DetailFormComponent.ParticipantInput
                  control={control}
                  name="mid_treatment_checkin"
                  type="date"
                  disabled
                />
              </DetailFormComponent.Group>
              <DetailFormComponent.Group>
                <DetailFormComponent.Label>
                  DAAF End of Treatment Checkin Date
                </DetailFormComponent.Label>
                <DetailFormComponent.ParticipantInput
                  control={control}
                  name="daaf_eot_checkin_date_time"
                  type="datetime-local"
                  disabled
                />
              </DetailFormComponent.Group>
              <DetailFormComponent.Group>
                <DetailFormComponent.Label>
                  SVR Reminder Date
                </DetailFormComponent.Label>
                <DetailFormComponent.ParticipantInput
                  control={control}
                  name="svr_reminder_date"
                  type="date"
                  disabled
                />
              </DetailFormComponent.Group>
              <DetailFormComponent.Group>
                <DetailFormComponent.Label>
                  Clinician letter sent
                </DetailFormComponent.Label>
                <DetailFormComponent.ParticipantInput
                  control={control}
                  name="clinician_letter_sent"
                  type="checkbox"
                  disabled={
                    nominateClinicianDisabled &&
                    getValues("allocated_prescriber_id") == ""
                  }
                />
              </DetailFormComponent.Group>
            </DetailFormComponent.Row>
            {/* 
            Sub-Section: is_cold_begin / contacts
            */}
            <DetailFormComponent.Row>
              <DetailFormComponent.Group>
                <DetailFormComponent.Label>
                  Has participant gone cold
                </DetailFormComponent.Label>
                <DetailFormComponent.ParticipantInput
                  control={control}
                  name="is_cold"
                  type="text"
                  disabled
                />
              </DetailFormComponent.Group>
              <DetailFormComponent.Label>
                Made Contact
              </DetailFormComponent.Label>
              <DetailFormComponent.Button
                type="button"
                txtColor="text-white"
                bgColor="bg-blue-500"
                bgHover="hover:bg-blue-600"
                onClick={() => {
                  setValue("contact_type1", "", {
                    shouldDirty: true,
                    shouldValidate: true,
                  });
                  setValue("contact_datetime1", "", {
                    shouldDirty: true,
                    shouldValidate: true,
                  });
                  setValue("contact_type2", "", {
                    shouldDirty: true,
                    shouldValidate: true,
                  });
                  setValue("contact_datetime2", "", {
                    shouldDirty: true,
                    shouldValidate: true,
                  });
                  setValue("contact_type3", "", {
                    shouldDirty: true,
                    shouldValidate: true,
                  });
                  setValue("contact_datetime3", "", {
                    shouldDirty: true,
                    shouldValidate: true,
                  });
                }}
              >
                Reset Contacts
              </DetailFormComponent.Button>
            </DetailFormComponent.Row>
            <DetailFormComponent.Section>
              <DetailFormComponent.Header sub>
                Contact Attempts
              </DetailFormComponent.Header>
              <DetailFormComponent.Row>
                {/*  Row: contact1  */}
                <DetailFormComponent.Group>
                  <DetailFormComponent.Label>
                    Type of contact 1
                  </DetailFormComponent.Label>
                  <DetailFormComponent.ParticipantInput
                    control={control}
                    name="contact_type1"
                    type="select"
                    selectType="contact_type"
                  />
                </DetailFormComponent.Group>
                <DetailFormComponent.Group>
                  <DetailFormComponent.Label>
                    Time of contact 1
                  </DetailFormComponent.Label>
                  <DetailFormComponent.ParticipantInput
                    control={control}
                    name="contact_datetime1"
                    type="datetime-local"
                  />
                </DetailFormComponent.Group>
              </DetailFormComponent.Row>
              <DetailFormComponent.Row>
                {/*  Row: contact2   */}
                <DetailFormComponent.Group>
                  <DetailFormComponent.Label>
                    Type of contact 2
                  </DetailFormComponent.Label>
                  <DetailFormComponent.ParticipantInput
                    control={control}
                    name="contact_type2"
                    type="select"
                    selectType="contact_type"
                  />
                </DetailFormComponent.Group>
                <DetailFormComponent.Group>
                  <DetailFormComponent.Label>
                    Time of contact 2
                  </DetailFormComponent.Label>
                  <DetailFormComponent.ParticipantInput
                    control={control}
                    name="contact_datetime2"
                    type="datetime-local"
                  />
                </DetailFormComponent.Group>
              </DetailFormComponent.Row>
              <DetailFormComponent.Row>
                {/*  Row: contact 3  */}
                <DetailFormComponent.Group>
                  <DetailFormComponent.Label>
                    Type of contact 3
                  </DetailFormComponent.Label>
                  <DetailFormComponent.ParticipantInput
                    control={control}
                    name="contact_type3"
                    type="select"
                    selectType="contact_type"
                  />
                </DetailFormComponent.Group>
                <DetailFormComponent.Group>
                  <DetailFormComponent.Label>
                    Time of contact 3
                  </DetailFormComponent.Label>
                  <DetailFormComponent.ParticipantInput
                    control={control}
                    name="contact_datetime3"
                    type="datetime-local"
                  />
                </DetailFormComponent.Group>
              </DetailFormComponent.Row>
            </DetailFormComponent.Section>
            {/* 
            Sub-Section: is_cold_END / contacts
            */}
          </DetailFormComponent.Section>
          {/* 
          Section: (START) Eligibility 
          */}
          <DetailFormComponent.Section>
            <DetailFormComponent.Header>Eligibility</DetailFormComponent.Header>
            <DetailFormComponent.Row>
              <DetailFormComponent.Group inline={true}>
                <DetailFormComponent.Label inline={true}>
                  Confirming you are 18 years or over?
                </DetailFormComponent.Label>
                <DetailFormComponent.ParticipantInput
                  control={control}
                  name="age_18_or_older"
                  type="select"
                  selectType="yes_no_na"
                  onChange={onEligibleChange}
                />
              </DetailFormComponent.Group>
              <DetailFormComponent.Group inline={true}>
                <DetailFormComponent.Label inline={true}>
                  You have self reported as having a Hep C infection?
                </DetailFormComponent.Label>
                <DetailFormComponent.ParticipantInput
                  control={control}
                  name="self_report_hep_c"
                  type="select"
                  selectType="yes_no_na"
                  onChange={onEligibleChange}
                />
              </DetailFormComponent.Group>
              <DetailFormComponent.Group inline={true}>
                <DetailFormComponent.Label inline={true}>
                  Do you have a Medicare card?
                </DetailFormComponent.Label>
                <DetailFormComponent.ParticipantInput
                  control={control}
                  name="has_medicare_card"
                  type="select"
                  selectType="yes_no_na"
                  onChange={onEligibleChange}
                />
              </DetailFormComponent.Group>
              <DetailFormComponent.Group inline={true}>
                <DetailFormComponent.Label inline={true}>
                  Are you living in Australia currently?
                </DetailFormComponent.Label>
                <DetailFormComponent.ParticipantInput
                  control={control}
                  name="resident_in_australia"
                  type="select"
                  selectType="yes_no_na"
                  onChange={onEligibleChange}
                />
              </DetailFormComponent.Group>
              <DetailFormComponent.Group inline={true}>
                <DetailFormComponent.Label inline={true}>
                  Are you on any Hep C treatment currently or received treatment
                  in the past 6 months?
                </DetailFormComponent.Label>
                <DetailFormComponent.ParticipantInput
                  control={control}
                  name="on_current_hep_c_treatment"
                  type="select"
                  selectType="yes_no_na"
                  onChange={onEligibleChange}
                />
              </DetailFormComponent.Group>
              <DetailFormComponent.Group inline={true}>
                <DetailFormComponent.Label inline={true}>
                  Have you previously enrolled in this project?
                </DetailFormComponent.Label>
                <DetailFormComponent.ParticipantInput
                  control={control}
                  name="previously_enrolled"
                  type="select"
                  selectType="yes_no_na"
                  onChange={onEligibleChange}
                />
              </DetailFormComponent.Group>
              <DetailFormComponent.Group inline={true}>
                <DetailFormComponent.Label inline={true}>
                  Are you able to provide consent?
                </DetailFormComponent.Label>
                <DetailFormComponent.ParticipantInput
                  control={control}
                  name="able_to_provide_consent"
                  type="select"
                  selectType="yes_no_na"
                  onChange={onEligibleChange}
                />
              </DetailFormComponent.Group>
              <DetailFormComponent.Group inline={true}>
                <DetailFormComponent.Label inline={true}>
                  Are you able to complete follow up (for instance undergo Hep C
                  testing and attend appointments)?
                </DetailFormComponent.Label>
                <DetailFormComponent.ParticipantInput
                  control={control}
                  name="able_complete_follow_up"
                  type="select"
                  selectType="yes_no_na"
                  onChange={onEligibleChange}
                />
              </DetailFormComponent.Group>
              <DetailFormComponent.Group inline={true}>
                <DetailFormComponent.Label inline={true}>
                  Are you currently pregnant or breastfeeding, which would
                  prevent you from commencing treatment in the next 12 weeks?
                </DetailFormComponent.Label>
                <DetailFormComponent.ParticipantInput
                  control={control}
                  name="contraindication_treatment"
                  type="select"
                  selectType="yes_no_na"
                  onChange={onEligibleChange}
                />
              </DetailFormComponent.Group>
              <DetailFormComponent.Group inline={true}>
                <DetailFormComponent.Label inline={true}>
                  Participant current eligibility
                </DetailFormComponent.Label>
                <div>{eligible}</div>
              </DetailFormComponent.Group>
              <DetailFormComponent.Group inline={false}>
                <DetailFormComponent.Label inline={true}>
                  Eligibility Date Time
                </DetailFormComponent.Label>
                <DetailFormComponent.ParticipantInput
                  control={control}
                  name="confirm_eligible_date_time"
                  type="datetime-local"
                  disabled
                />
              </DetailFormComponent.Group>
            </DetailFormComponent.Row>
            <DetailFormComponent.Row>
              {showStatusTransition()}
            </DetailFormComponent.Row>
          </DetailFormComponent.Section>
          {/* 
          Section: (END) Eligibility 
          */}

          {/* 
          Section: (START) Consent 
          */}
          <DetailFormComponent.Section>
            <DetailFormComponent.Header>Consent</DetailFormComponent.Header>
            <DetailFormComponent.Row>
              <DetailFormComponent.Group inline={true}>
                <DetailFormComponent.Label inline={true}>
                  Can I confirm that you have read the Participant Information
                  Sheet or someone has read it to you in a language that you
                  understand?
                </DetailFormComponent.Label>
                <DetailFormComponent.ParticipantInput
                  control={control}
                  name="has_read_pi_sheet"
                  type="select"
                  selectType="yn"
                />
              </DetailFormComponent.Group>
              <DetailFormComponent.Group inline={true}>
                <DetailFormComponent.Label inline={true}>
                  Can I confirm you understand the purpose, procedures and risks
                  of this project?
                </DetailFormComponent.Label>
                <DetailFormComponent.ParticipantInput
                  control={control}
                  name="understands_procedures_risk"
                  type="select"
                  selectType="yn"
                />
              </DetailFormComponent.Group>
              <DetailFormComponent.Group inline={true}>
                <DetailFormComponent.Label inline={true}>
                  Can I confirm that you have had an opportunity to ask
                  questions and had your queries answered satisfactorily?
                </DetailFormComponent.Label>
                <DetailFormComponent.ParticipantInput
                  control={control}
                  name="queries_answered"
                  type="select"
                  selectType="yn"
                />
              </DetailFormComponent.Group>
              <DetailFormComponent.Group inline={true}>
                <DetailFormComponent.Label inline={true}>
                  Can I confirm that you freely agree to participate in this
                  research project?
                </DetailFormComponent.Label>
                <DetailFormComponent.ParticipantInput
                  control={control}
                  name="freely_agrees_to_participate"
                  type="select"
                  selectType="yn"
                />
              </DetailFormComponent.Group>
              <DetailFormComponent.Group inline={true}>
                <DetailFormComponent.Label inline={true}>
                  Can I confirm that you are aware that you can withdraw at any
                  time without giving a reason during the project and it will
                  not affect your future health care?
                </DetailFormComponent.Label>
                <DetailFormComponent.ParticipantInput
                  control={control}
                  name="aware_of_project_withdrawal"
                  type="select"
                  selectType="yn"
                />
              </DetailFormComponent.Group>
              <DetailFormComponent.Group inline={true}>
                <DetailFormComponent.Label inline={true}>
                  Can you confirm that you are aware and consent for the
                  University of Sydney to give your name, address (if
                  applicable) and phone number to iGoDirect True Rewards payment
                  vendor to facilitate the payments that need to be made to you
                  for the purpose of this project?
                </DetailFormComponent.Label>
                <DetailFormComponent.ParticipantInput
                  control={control}
                  name="consents_to_pi_use"
                  type="select"
                  selectType="yn"
                />
              </DetailFormComponent.Group>
            </DetailFormComponent.Row>

            <DetailFormComponent.Row>
              <DetailFormComponent.Group>
                <DetailFormComponent.Label>
                  (Optional) Can University of Sydney contact you for other
                  research opportunities (please note this is optional and you
                  can continue with this project without agreeing to be
                  contacted for future opportunities)?
                </DetailFormComponent.Label>
                <DetailFormComponent.ParticipantInput
                  control={control}
                  name="future_research_opt"
                  type="select"
                  selectType="yn"
                />
              </DetailFormComponent.Group>
              <DetailFormComponent.Group>
                <DetailFormComponent.Label>
                  Consent Confirmed
                </DetailFormComponent.Label>
                <DetailFormComponent.ParticipantInput
                  control={control}
                  name="navigator_confirm_consent"
                  type="select"
                  selectType="yn"
                />
              </DetailFormComponent.Group>
              <DetailFormComponent.Group>
                <DetailFormComponent.Label>
                  Consent Date Time
                </DetailFormComponent.Label>
                <DetailFormComponent.ParticipantInput
                  control={control}
                  name="consent_date_time"
                  type="datetime-local"
                  disabled
                />
              </DetailFormComponent.Group>
              <DetailFormComponent.Group>
                <DetailFormComponent.Label>
                  Consented By
                </DetailFormComponent.Label>
                <DetailFormComponent.ParticipantInput
                  control={control}
                  name="consented_by"
                  type="select"
                  dynamicSelections={navigators}
                  dynamicSelectType
                />
              </DetailFormComponent.Group>
              <DetailFormComponent.Group>
                <DetailFormComponent.Label>
                  Consent Comments
                </DetailFormComponent.Label>
                <DetailFormComponent.ParticipantInput
                  control={control}
                  name="consent_comments"
                  type="textarea"
                />
              </DetailFormComponent.Group>
            </DetailFormComponent.Row>
            <DetailFormComponent.Row>
              {showStatusTransition()}
            </DetailFormComponent.Row>
          </DetailFormComponent.Section>
          {/* 
          Section: (END) Consent 
          */}

          {/* 
          Section: (START) Payment 
          */}
          <DetailFormComponent.Section>
            <DetailFormComponent.Header>Payment</DetailFormComponent.Header>
            <DetailFormComponent.Row>
              <DetailFormComponent.Group>
                <DetailFormComponent.Label>
                  Payment Method
                </DetailFormComponent.Label>
                <DetailFormComponent.ParticipantInput
                  control={control}
                  name="payment_method"
                  type="select"
                  selectType="payment"
                />
              </DetailFormComponent.Group>
              {getValues("payment_method") ==
              config.form.select.payment[1].value ? (
                <>
                  <DetailFormComponent.Group>
                    <DetailFormComponent.Label>
                      Street
                    </DetailFormComponent.Label>
                    <DetailFormComponent.ParticipantInput
                      control={control}
                      name="mailing_street"
                      type="text"
                    />
                  </DetailFormComponent.Group>
                  <DetailFormComponent.Group>
                    <DetailFormComponent.Label>City</DetailFormComponent.Label>
                    <DetailFormComponent.ParticipantInput
                      control={control}
                      name="mailing_city"
                      type="text"
                    />
                  </DetailFormComponent.Group>
                  <DetailFormComponent.Group>
                    <DetailFormComponent.Label>
                      Post Code
                    </DetailFormComponent.Label>
                    <DetailFormComponent.ParticipantInput
                      control={control}
                      name="mailing_post_code"
                      type="text"
                    />
                  </DetailFormComponent.Group>
                  <DetailFormComponent.Group>
                    <DetailFormComponent.Label>State</DetailFormComponent.Label>
                    <DetailFormComponent.ParticipantInput
                      control={control}
                      name="mailing_state"
                      type="select"
                      selectType="state"
                    />
                  </DetailFormComponent.Group>
                </>
              ) : null}
            </DetailFormComponent.Row>
          </DetailFormComponent.Section>
          {/* 
          Section: (END) Payment 
          */}

          {/* 
          Section: (START) Clinician Nominate
          */}
          <DetailFormComponent.Section>
            <DetailFormComponent.Header>
              Clinician Nominate
            </DetailFormComponent.Header>
            {getValues("did_request_prescriber") == "No" ? (
              <Banner
                alert
                heading="Attention! Please fill out the default clinician allocation section."
              >
                If a clinician is not nominated, then a default clinician must
                be allocated.
              </Banner>
            ) : null}
            <DetailFormComponent.Row>
              <DetailFormComponent.Group>
                <DetailFormComponent.Label>
                  Do you have a GP?
                </DetailFormComponent.Label>
                <DetailFormComponent.ParticipantInput
                  control={control}
                  name="did_request_prescriber"
                  type="select"
                  selectType="ynLiteral"
                  onChange={onClinicianNominateChange}
                />
                <DetailFormComponent.Button
                  type="button"
                  txtColor="text-white"
                  bgColor="bg-blue-500"
                  bgHover="hover:bg-blue-600"
                  onClick={() => setAddClinicianModalOpen(true)}
                >
                  Add Clinician
                </DetailFormComponent.Button>
              </DetailFormComponent.Group>
              <DetailFormComponent.Group>
                <DetailFormComponent.Label>
                  Nominated Clinician
                </DetailFormComponent.Label>
                <DetailFormComponent.ParticipantInput
                  control={control}
                  name="requested_prescriber_id"
                  type="select"
                  dynamicSelections={filterClinicians(clinicians?.data)}
                  dynamicSelectType
                  disabled={
                    nominateClinicianDisabled ||
                    getValues("allocated_prescriber_id") != ""
                  }
                />
                {getValues("randomised_clinician") != "" &&
                getValues("status") == "clinician_nominated" &&
                getValues("requested_prescriber_id") != "" ? (
                  <LinkButton
                    txtColor="text-white"
                    bgColor="bg-blue-500"
                    bgHover="hover:bg-blue-600"
                    linkTo={`/api/v1/clinicians/${getValues(
                      "requested_prescriber_id"
                    )}/invite?part_id=${getValues("record_id")}`}
                    target="_blank"
                    redirect
                  >
                    Generate Invite PDF
                  </LinkButton>
                ) : null}
              </DetailFormComponent.Group>
            </DetailFormComponent.Row>
            <DetailFormComponent.Row>
              <DetailFormComponent.Group>
                <DetailFormComponent.Label>
                  Clinician Randomisation Allocation
                </DetailFormComponent.Label>
                <DetailFormComponent.ParticipantInput
                  control={control}
                  name="randomised_clinician"
                  type="text"
                  disabled
                />
              </DetailFormComponent.Group>
              {/* May move to another section */}
              {/* <DetailFormComponent.Group>
                <DetailFormComponent.Label>
                  Clinician Payment Amount
                </DetailFormComponent.Label>
                <DetailFormComponent.ParticipantInput
                  control={control}
                  name="clinician_payment_amount"
                  type="text"
                  disabled
                />
              </DetailFormComponent.Group> */}
            </DetailFormComponent.Row>
            <DetailFormComponent.Row>
              {showStatusTransition()}
            </DetailFormComponent.Row>
          </DetailFormComponent.Section>
          {/* 
          Section: (END) Clinician Nominate
          */}

          {/* 
          Section: (START) Default Clinician Allocate
          */}

          <DetailFormComponent.Section>
            <DetailFormComponent.Header>
              Default Clinician Allocate
            </DetailFormComponent.Header>
            {getValues("allocated_prescriber_id") != "" ? (
              <Banner
                heading="Default Clinician Allocated"
                message="The default clinician will take precedence over the nominated clinician."
              />
            ) : null}
            <DetailFormComponent.Row>
              <DetailFormComponent.Group>
                <DetailFormComponent.Label>
                  Allocated Default Clinician
                </DetailFormComponent.Label>
                <DetailFormComponent.ParticipantInput
                  control={control}
                  name="allocated_prescriber_id"
                  type="select"
                  dynamicSelections={filterClinicians(clinicians?.data, true)}
                  dynamicSelectType
                />
              </DetailFormComponent.Group>
            </DetailFormComponent.Row>
            <DetailFormComponent.Row>
              {showStatusTransition()}
            </DetailFormComponent.Row>
          </DetailFormComponent.Section>
          {/* 
          Section: (END) Default Clinician Allocate
          */}

          {/* 
          Section: (START) HCV Test Results 
          */}
          <DetailFormComponent.Section>
            <DetailFormComponent.Header>
              HCV Test Results
            </DetailFormComponent.Header>

            <DetailFormComponent.Row>
              <DetailFormComponent.Group inline={true}>
                <DetailFormComponent.Label inline={true}>
                  HCV Test Results
                </DetailFormComponent.Label>
                <DetailFormComponent.ParticipantInput
                  control={control}
                  name="hcv_test_results"
                  type="select"
                  selectType="hcv_test_results"
                />
              </DetailFormComponent.Group>
            </DetailFormComponent.Row>
            <DetailFormComponent.Row>
              <DetailFormComponent.Group inline={true}>
                <DetailFormComponent.Label inline={true}>
                  HCV Test Date
                </DetailFormComponent.Label>
                <DetailFormComponent.ParticipantInput
                  control={control}
                  name="hcv_test_date"
                  type="date"
                />
              </DetailFormComponent.Group>
            </DetailFormComponent.Row>
            <DetailFormComponent.Row>
              <DetailFormComponent.Group inline={true}>
                <DetailFormComponent.Label inline={true}>
                  Comments
                </DetailFormComponent.Label>
                <DetailFormComponent.ParticipantInput
                  control={control}
                  name="hcv_test_comments"
                  type="textarea"
                />
              </DetailFormComponent.Group>
            </DetailFormComponent.Row>
            <DetailFormComponent.Row>
              <DetailFormComponent.Group>
                <DetailFormComponent.Label>
                  HCV Test Result Confirmation Date Time
                </DetailFormComponent.Label>
                <DetailFormComponent.ParticipantInput
                  control={control}
                  name="hcv_test_confirm_date_time"
                  type="datetime-local"
                />
                {getValues("hcv_test_confirm_date_time") != "" ? (
                  <DetailFormComponent.Button
                    type="button"
                    txtColor="text-white"
                    bgColor="bg-slate-500"
                    bgHover="hover:bg-slate-600"
                    onClick={() =>
                      setValue("hcv_test_confirm_date_time", "", {
                        shouldDirty: true,
                        shouldValidate: true,
                      })
                    }
                  >
                    Reset Date Time
                  </DetailFormComponent.Button>
                ) : (
                  <DetailFormComponent.Button
                    type="button"
                    txtColor="text-white"
                    bgColor="bg-blue-500"
                    bgHover="hover:bg-blue-600"
                    onClick={() =>
                      setValue(
                        "hcv_test_confirm_date_time",
                        getDatetimeString(),
                        {
                          shouldDirty: true,
                          shouldValidate: true,
                        }
                      )
                    }
                  >
                    Now
                  </DetailFormComponent.Button>
                )}
              </DetailFormComponent.Group>
            </DetailFormComponent.Row>
            <DetailFormComponent.Row>
              {showStatusTransition()}
            </DetailFormComponent.Row>
          </DetailFormComponent.Section>
          {/* 
          Section: (END) HCV Test Results 
          */}

          {/* 
          Section: (START) DAA Verification 
          */}
          <DetailFormComponent.Section>
            <DetailFormComponent.Header>
              DAA Verification
            </DetailFormComponent.Header>
            {/* daa_verified_date_time: string(), 
            daa_tmt_schedule: string(), daa_tmt_start_date_time: string(),
            daa_tmt_complete_date_time: string(), */}
            <DetailFormComponent.Row>
              <DetailFormComponent.Group>
                <DetailFormComponent.Label>
                  DAA Scheduled Meeting Date/Time
                </DetailFormComponent.Label>
                <DetailFormComponent.ParticipantInput
                  control={control}
                  name="daa_meeting_date_time"
                  type="datetime-local"
                />
              </DetailFormComponent.Group>
              <DetailFormComponent.Group>
                <DetailFormComponent.Label>
                  DAA Verified?
                </DetailFormComponent.Label>
                <DetailFormComponent.ParticipantInput
                  control={control}
                  name="daa_verified"
                  type="select"
                  selectType="daa_verified"
                />
              </DetailFormComponent.Group>
            </DetailFormComponent.Row>
            <DetailFormComponent.Row>
              <DetailFormComponent.Group>
                <DetailFormComponent.Label>
                  DAA Verified Date/Time
                </DetailFormComponent.Label>
                <DetailFormComponent.ParticipantInput
                  control={control}
                  name="daa_verified_date_time"
                  type="datetime-local"
                />
              </DetailFormComponent.Group>
              <DetailFormComponent.Group>
                <DetailFormComponent.Label>
                  DAA Treatment Schedule (Weeks)
                </DetailFormComponent.Label>
                <DetailFormComponent.ParticipantInput
                  control={control}
                  name="daa_tmt_schedule"
                  type="text"
                />
              </DetailFormComponent.Group>
            </DetailFormComponent.Row>
            <DetailFormComponent.Row>
              <DetailFormComponent.Group>
                <DetailFormComponent.Label>
                  DAA Start Date/Time
                </DetailFormComponent.Label>
                <DetailFormComponent.ParticipantInput
                  control={control}
                  name="daa_tmt_start_date_time"
                  type="datetime-local"
                />
              </DetailFormComponent.Group>
              <DetailFormComponent.Group>
                <DetailFormComponent.Label>
                  DAA Calculated Completion Date/Time
                </DetailFormComponent.Label>
                <DetailFormComponent.ParticipantInput
                  control={control}
                  name="daa_tmt_complete_date_time"
                  type="datetime-local"
                  disabled
                />
              </DetailFormComponent.Group>
            </DetailFormComponent.Row>

            <DetailFormComponent.Row>
              <DetailFormComponent.Group>
                <DetailFormComponent.Label>
                  Name of Participant Verified?
                </DetailFormComponent.Label>
                <DetailFormComponent.ParticipantInput
                  control={control}
                  name="daa_participant_name_verified"
                  type="select"
                  selectType="pending_yes_no"
                />
              </DetailFormComponent.Group>
              <DetailFormComponent.Group>
                <DetailFormComponent.Label>
                  Name of clinician / specialist verified?
                </DetailFormComponent.Label>
                <DetailFormComponent.ParticipantInput
                  control={control}
                  name="daa_clinician_name_verified"
                  type="select"
                  selectType="pending_yes_no"
                />
              </DetailFormComponent.Group>
            </DetailFormComponent.Row>
            <DetailFormComponent.Row>
              <DetailFormComponent.Group>
                <DetailFormComponent.Label>
                  Date of Treatment Dispensed
                </DetailFormComponent.Label>
                <DetailFormComponent.ParticipantInput
                  control={control}
                  name="daa_tmt_dispensed_date"
                  type="date"
                />
              </DetailFormComponent.Group>
              <DetailFormComponent.Group>
                <DetailFormComponent.Label>
                  Name of Treatment
                </DetailFormComponent.Label>
                <DetailFormComponent.ParticipantInput
                  control={control}
                  name="daa_tmt_name"
                  type="text"
                />
              </DetailFormComponent.Group>
            </DetailFormComponent.Row>
            <DetailFormComponent.Row>
              <DetailFormComponent.Group>
                <DetailFormComponent.Label>
                  Number of Repeats
                </DetailFormComponent.Label>
                <DetailFormComponent.ParticipantInput
                  control={control}
                  name="daa_tmt_repeats"
                  type="text"
                />
              </DetailFormComponent.Group>
            </DetailFormComponent.Row>
            <DetailFormComponent.Row>
              {showStatusTransition()}
            </DetailFormComponent.Row>
          </DetailFormComponent.Section>
          {/* 
          Section: (END) DAA Verification 
          */}

          {/* 
          Section: (START) Mid Treatment Check-in
          */}
          <DetailFormComponent.Section>
            <DetailFormComponent.Header>
              Mid Treatment Check-in
            </DetailFormComponent.Header>
            <DetailFormComponent.Row>
              <DetailFormComponent.Group>
                <DetailFormComponent.Label>
                  Were you able to receive the incentive amount?
                </DetailFormComponent.Label>
                <DetailFormComponent.ParticipantInput
                  control={control}
                  name="daaf_mt_incentive_received"
                  type="select"
                  selectType="ynLiteral"
                />
              </DetailFormComponent.Group>
              <DetailFormComponent.Group>
                <DetailFormComponent.Label>
                  Incentive amount receipt issues
                </DetailFormComponent.Label>
                <DetailFormComponent.ParticipantInput
                  control={control}
                  name="daaf_mt_incentive_problems_text"
                  type="textarea"
                />
              </DetailFormComponent.Group>
              <DetailFormComponent.Group>
                <DetailFormComponent.Label>
                  Have you experienced any problems since commencing treatment?
                </DetailFormComponent.Label>
                <DetailFormComponent.ParticipantInput
                  control={control}
                  name="daaf_any_problems"
                  type="select"
                  selectType="ynLiteral"
                />
              </DetailFormComponent.Group>
              <DetailFormComponent.Group>
                <DetailFormComponent.Label>
                  Treatment Problems Encountered (Adverse Events)
                </DetailFormComponent.Label>
                <DetailFormComponent.ParticipantInput
                  control={control}
                  name="daaf_any_problems_text"
                  type="textarea"
                />
              </DetailFormComponent.Group>
              <DetailFormComponent.Group>
                <DetailFormComponent.Label>
                  DAAF End of treatment check-in (Calculated)
                </DetailFormComponent.Label>
                <DetailFormComponent.ParticipantInput
                  control={control}
                  name="daaf_eot_checkin_date_time"
                  type="datetime-local"
                  disabled
                />
              </DetailFormComponent.Group>
              <DetailFormComponent.Group>
                <DetailFormComponent.Label>
                  DAAF End of treatment check-in (Meeting)
                </DetailFormComponent.Label>
                <DetailFormComponent.ParticipantInput
                  control={control}
                  name="daaf_eot_checkin_meeting_date_time"
                  type="datetime-local"
                />
              </DetailFormComponent.Group>
            </DetailFormComponent.Row>
            <DetailFormComponent.Row>
              {showStatusTransition()}
            </DetailFormComponent.Row>
          </DetailFormComponent.Section>
          {/* 
          Section: (END) Mid Treatment Check-in
          */}

          {/* 
          Section: (START) End of Treatment Check-in
          */}
          <DetailFormComponent.Section>
            <DetailFormComponent.Header>
              End of Treatment Check-in
            </DetailFormComponent.Header>
            <DetailFormComponent.Row>
              <DetailFormComponent.Group inline>
                <DetailFormComponent.Label inline>
                  Would you like to answer a question on your treatment
                  experience?
                </DetailFormComponent.Label>
                <DetailFormComponent.ParticipantInput
                  control={control}
                  name="daaf_sms_consented"
                  type="select"
                  selectType="ynLiteral"
                />
              </DetailFormComponent.Group>
              <>
                <DetailFormComponent.Group inline>
                  <DetailFormComponent.Label inline>
                    In total how many days of treatment have you missed?
                  </DetailFormComponent.Label>
                  <DetailFormComponent.ParticipantInput
                    control={control}
                    name="daaf_days_tmt_missed"
                    type="select"
                    selectType="daaf_days_tmt_missed"
                  />
                </DetailFormComponent.Group>
              </>
            </DetailFormComponent.Row>
            <Banner heading="Inform Participant of: ">
              {config.form.checkbox.inform.eot.map((inform, idx) => {
                return (
                  <DetailFormComponent.ControlledCheckbox
                    key={`eot_inform_${idx}`}
                    definition={inform.definition}
                    description=""
                    value={inform.order}
                  />
                );
              })}
            </Banner>
            <DetailFormComponent.Row>
              {showStatusTransition()}
            </DetailFormComponent.Row>
          </DetailFormComponent.Section>
          {/* 
          Section: (END) End of Treatment Check-in
          */}

          {/* 
          Section: (START) SVR Test
          */}
          <DetailFormComponent.Section>
            <DetailFormComponent.Header>
              SVR Test Results
            </DetailFormComponent.Header>
            <DetailFormComponent.Row>
              <DetailFormComponent.Group inline={true}>
                <DetailFormComponent.Label inline={true}>
                  SVR Test Results
                </DetailFormComponent.Label>
                <DetailFormComponent.ParticipantInput
                  control={control}
                  name="svr_test_results"
                  type="select"
                  selectType="svr_test_results"
                />
              </DetailFormComponent.Group>
            </DetailFormComponent.Row>
            <DetailFormComponent.Row>
              <DetailFormComponent.Group inline={true}>
                <DetailFormComponent.Label inline={true}>
                  SVR Test Date
                </DetailFormComponent.Label>
                <DetailFormComponent.ParticipantInput
                  control={control}
                  name="svr_test_date"
                  type="date"
                />
              </DetailFormComponent.Group>
            </DetailFormComponent.Row>
            <DetailFormComponent.Row>
              <DetailFormComponent.Group inline={true}>
                <DetailFormComponent.Label inline={true}>
                  Comments
                </DetailFormComponent.Label>
                <DetailFormComponent.ParticipantInput
                  control={control}
                  name="svr_test_comments"
                  type="textarea"
                />
              </DetailFormComponent.Group>
            </DetailFormComponent.Row>
            <DetailFormComponent.Row>
              <DetailFormComponent.Group>
                <DetailFormComponent.Label>
                  SVR Test Result Confirmation Date Time
                </DetailFormComponent.Label>
                <DetailFormComponent.ParticipantInput
                  control={control}
                  name="svr_test_confirm_date_time"
                  type="datetime-local"
                />
                {getValues("svr_test_confirm_date_time") != "" ? (
                  <DetailFormComponent.Button
                    type="button"
                    txtColor="text-white"
                    bgColor="bg-slate-500"
                    bgHover="hover:bg-slate-600"
                    onClick={() =>
                      setValue("svr_test_confirm_date_time", "", {
                        shouldDirty: true,
                        shouldValidate: true,
                      })
                    }
                  >
                    Reset Date Time
                  </DetailFormComponent.Button>
                ) : (
                  <DetailFormComponent.Button
                    type="button"
                    txtColor="text-white"
                    bgColor="bg-blue-500"
                    bgHover="hover:bg-blue-600"
                    onClick={() =>
                      setValue(
                        "svr_test_confirm_date_time",
                        getDatetimeString(),
                        {
                          shouldDirty: true,
                          shouldValidate: true,
                        }
                      )
                    }
                  >
                    Now
                  </DetailFormComponent.Button>
                )}
              </DetailFormComponent.Group>
            </DetailFormComponent.Row>
            <DetailFormComponent.Row>
              {showStatusTransition()}
            </DetailFormComponent.Row>
          </DetailFormComponent.Section>
          {/* 
          Section: (END) SVR Test
          */}
          <FloatingContainer placement="bot-right">
            <DetailFormComponent.Row>
              <DetailFormComponent.Button
                type="button"
                txtColor="text-white"
                bgColor="bg-blue-500"
                bgHover="hover:bg-blue-600"
                onClick={() => setConfirmDataModalOpen(true)}
              >
                Update Participant
              </DetailFormComponent.Button>
              <DetailFormComponent.Button
                type="button"
                txtColor="text-white"
                bgColor="bg-slate-500"
                bgHover="hover:bg-slate-600"
                onClick={() => reset(latestParticipantData)}
              >
                Reset Form
              </DetailFormComponent.Button>
            </DetailFormComponent.Row>
          </FloatingContainer>
        </DetailFormComponent.Content>
      </DetailFormComponent>
    </>
  );
};

/* Under Dev; Use of config to map through form fields */
// import config from "../../app/detail.form.config";

// return (
//   <>
//     <DetailFormComponent>
//       <DetailFormComponent.Content
//         onReset={onReset}
//         onSubmit={handleSubmit(onSubmit)}
//       >
//         {config.form.participantDetail.map((section, idx) => (
//           <>
//             <DetailFormComponent.Header key={`${section.header}${idx}`}>
//               {section.header}
//             </DetailFormComponent.Header>
//             <DetailFormComponent.Row>
//               {section.inputs.map((input, idx) => (
//                 <DetailFormComponent.Group key={`${input.fieldName}${idx}`}>
//                   <DetailFormComponent.Label>
//                     {input.displayName}
//                   </DetailFormComponent.Label>
//                   <DetailFormComponent.Input
//                     control={control}
//                     /* Current issue, string literal TName does not accept type string */
//                     name={input.fieldName}
//                     type={input.type}
//                     rules={input.rules}
//                     disabled={input.disabled}
//                   />
//                 </DetailFormComponent.Group>
//               ))}
//             </DetailFormComponent.Row>
//           </>
//         ))}
//       </DetailFormComponent.Content>
//     </DetailFormComponent>
//   </>
// );
