import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

/* Components */
import { ParticipantDetailForm } from "./ParticipantDetailForm";
import {
  NavigateBackButton,
  LoadingScreen,
  FloatingContainer,
} from "../../utils/components/Common";

/* APIs - Utils */
import { useGetParticipantQuery } from "./participantApi";
import { useGetNavigatorsQuery } from "../user/userApi";
import { useGetStatusGraphQuery } from "./statusGraphApi";
import { toastQueryError } from "../../utils/toasts";

const ParticipantDetail = (): JSX.Element => {
  const { pk } = useParams();
  const [processing, setProcessing] = useState(true);
  const participant = useGetParticipantQuery(pk);
  const navigators = useGetNavigatorsQuery();

  const statusGraph = useGetStatusGraphQuery();

  useEffect(() => {
    if (participant.isError && participant.error) {
      toastQueryError(participant.error);
    }
  }, [participant.isError]);

  useEffect(() => {
    if (participant.isFetching || participant.isLoading) {
      setProcessing(true);
    } else {
      setProcessing(false);
    }
  }, [participant.isLoading, participant.isFetching]);

  return (
    <>
      {processing ? <LoadingScreen /> : null}
      {participant.isSuccess &&
      navigators.isSuccess &&
      statusGraph.isSuccess ? (
        <>
          <FloatingContainer placement="bot-left">
            <NavigateBackButton>⬅️ Go Back</NavigateBackButton>
          </FloatingContainer>
          <ParticipantDetailForm
            navigators={navigators.data}
            statusGraph={statusGraph.data}
            participant={participant.data}
          />
        </>
      ) : null}
    </>
  );
};

export default ParticipantDetail;
