import React from "react";
import { Container, LinkButton } from "../../utils/components/Common";

type ProfileCardComponentProps = {
  children?: React.ReactNode;
};

export const ProfileCardComponent = ({
  children,
}: ProfileCardComponentProps): JSX.Element => {
  return (
    <>
      <Container padding="px-16 py-6">{children}</Container>
    </>
  );
};

type ContentProps = {
  children?: React.ReactNode;
};

export const Content = ({ children }: ContentProps): JSX.Element => {
  return (
    <>
      <div className="p-8 bg-white shadow-lg mt-24">{children}</div>
    </>
  );
};

type UserShowcaseProps = {
  children?: React.ReactNode;
};

export const UserShowcase = ({ children }: UserShowcaseProps): JSX.Element => {
  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-3">{children}</div>
    </>
  );
};

type UserActionProps = {
  children?: React.ReactNode;
};

export const UserAction = ({ children }: UserActionProps): JSX.Element => {
  return (
    <>
      <div className="grid grid-cols-2 text-center order-last md:order-first mt-20 md:mt-0">
        {children}
      </div>
    </>
  );
};

type UserActionItemProps = {
  count?: string;
  actionName?: string;
};

export const UserActionItem = ({
  count,
  actionName,
}: UserActionItemProps): JSX.Element => {
  return (
    <>
      <div>
        <p className="font-bold text-gray-700 text-xl">{count}</p>
        <p className="text-gray-400">{actionName}</p>
      </div>
    </>
  );
};

type UserPictureProps = {
  children?: React.ReactNode;
};

export const UserPicture = ({ children }: UserPictureProps): JSX.Element => {
  return (
    <>
      <div className="relative">
        <div className="w-48 h-48 bg-indigo-100 mx-auto rounded-full shadow-2xl absolute inset-x-0 top-0 -mt-24 flex items-center justify-center text-indigo-500">
          {children}
        </div>
      </div>
    </>
  );
};

type UserCallToActionProps = {
  children?: React.ReactNode;
};

export const UserCallToAction = ({
  children,
}: UserCallToActionProps): JSX.Element => {
  return (
    <>
      <div className="space-x-8 flex justify-center mt-32 md:mt-0 md:justify-center">
        {children}
      </div>
    </>
  );
};

type UserCallToActionButtonProps = {
  linkTo: string;
  children?: React.ReactNode;
};

export const UserCallToActionButton = ({
  linkTo,
  children,
}: UserCallToActionButtonProps): JSX.Element => {
  return (
    <>
      <LinkButton
        txtColor="text-white"
        bgColor="bg-slate-600"
        bgHover="hover:bg-slate-600"
        linkTo={linkTo}
      >
        {children}
      </LinkButton>
    </>
  );
};

type UserDetailProps = {
  children?: React.ReactNode;
};

export const UserDetail = ({ children }: UserDetailProps): JSX.Element => {
  return (
    <>
      <div className="mt-20 text-center pb-10">{children}</div>
    </>
  );
};

type UserBlurbProps = {
  children?: React.ReactNode;
};

export const UserBlurb = ({ children }: UserBlurbProps): JSX.Element => {
  return (
    <>
      <div className="mt-12 flex flex-col justify-center">{children}</div>
    </>
  );
};

ProfileCardComponent.Content = Content;

ProfileCardComponent.UserShowcase = UserShowcase;
ProfileCardComponent.UserAction = UserAction;
ProfileCardComponent.UserActionItem = UserActionItem;
ProfileCardComponent.UserPicture = UserPicture;
ProfileCardComponent.UserCallToAction = UserCallToAction;
ProfileCardComponent.UserCallToActionButton = UserCallToActionButton;

ProfileCardComponent.UserDetail = UserDetail;
ProfileCardComponent.UserBlurb = UserBlurb;

export default ProfileCardComponent;
