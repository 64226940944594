import React, { useEffect, useState } from "react";
import { useGetInfoQuery } from "./infoApi";
import {
  Container,
  LinkButton,
  LoadingScreen,
} from "../../utils/components/Common";
import { toastQueryError } from "../../utils/toasts";
import { ActionCntnr, ActionDropdown } from "../navbar/Navbar.elements";

interface InfoProps {
  name?: boolean;
  version?: boolean;
  description?: boolean;
  authors?: boolean;
  user_manual_url?: boolean;
}

const Info = (props: InfoProps): JSX.Element => {
  const [processing, setProcessing] = useState(true);
  const { data, isFetching, isLoading, isError, error } = useGetInfoQuery();

  useEffect(() => {
    if (isError && error) {
      toastQueryError(error);
    }
  }, [isError]);

  useEffect(() => {
    if (isFetching || isLoading) {
      setProcessing(true);
    } else {
      setProcessing(false);
    }
  }, [isLoading, isFetching]);

  return (
    <>
      {processing ? <LoadingScreen /> : null}
      {data ? (
        <Container>
          <ActionCntnr>
            {props.version ? (
              <div className="text-white mr-10">{data.version}</div>
            ) : null}
            {props.user_manual_url ? (
              <ActionDropdown>
                <LinkButton
                  txtColor="text-grey-800"
                  bgColor="bg-gray-200 py-2 px-4 block whitespace-no-wrap"
                  bgHover="hover:bg-gray-400"
                  linkTo={data.user_manual_url}
                  redirect
                  target="_blank"
                >
                  User Manual
                </LinkButton>
              </ActionDropdown>
            ) : null}
            {props.name ? <div>{data.name}</div> : null}
            {props.description ? <div>{data.description}</div> : null}
            {props.authors
              ? Object.entries(data.authors).map(([key, value]) => (
                  <div key={key}>
                    {key}({value})
                  </div>
                ))
              : null}
          </ActionCntnr>
        </Container>
      ) : null}
    </>
  );
};

export default Info;
