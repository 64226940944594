import React from "react";
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

import { CardIssued } from "./PaymentReportApi";

type PieChartComponentProps = {
  data: CardIssued[];
  dataKey: string;
  nameKey: string;
};

const COLOURS = {
  Blue: "#0088FE",
  Green: "#00C49F",
  Yellow: "#FFBB28",
  Orange: "#FF8042",
  Pink: "#FF6384",
};

const CHART_COLOURS = [
  COLOURS.Blue,
  COLOURS.Green,
  COLOURS.Yellow,
  COLOURS.Orange,
  COLOURS.Pink,
];

export const PieChartComponent = ({
  data,
  dataKey,
  nameKey,
}: PieChartComponentProps): JSX.Element => {
  return (
    <ResponsiveContainer width="100%" height={400}>
      <PieChart>
        <Pie
          data={data}
          dataKey={dataKey}
          nameKey={nameKey}
          cx="50%"
          cy="50%"
          outerRadius={150}
          fill="#8884d8"
          label
        >
          {data.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={CHART_COLOURS[index % CHART_COLOURS.length]}
            />
          ))}
        </Pie>
        <Tooltip />
        <Legend />
      </PieChart>
    </ResponsiveContainer>
  );
};
