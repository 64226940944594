import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";

/* Config */
import config from "./app/config.json";

/* Views */
import LoginCallback from "./features/auth/LoginCallback";
import Landing from "./features/landing/Landing";
import Profile from "./features/user/Profile";
import Dashboard from "./features/user/Dashboard";
import Workspace from "./features/user/Workspace";
import ParticipantDetail from "./features/participant/ParticipantDetail";
import ClinicianDetail from "./features/clinician/ClinicianDetail";
import ClinicianAdd from "./features/clinician/ClinicianAdd";

/* Components */
import AppNavbar from "./features/navbar/Navbar";

/* APIs - Utils*/
import { PrivateOutlet } from "./utils/components/PrivateOutlet";
import { useCsrfPingMutation } from "./features/auth/authApi";
import { toastQueryError } from "./utils/toasts";

function App() {
  let didSendPing = false;
  const [csrfPing, { isError, error }] = useCsrfPingMutation();

  useEffect(() => {
    if (!didSendPing) {
      csrfPing();
      didSendPing = true;
    }
  }, []);

  useEffect(() => {
    if (error) {
      toastQueryError(error);
    }
  }, [isError, error]);

  return (
    <>
      <ToastContainer />
      <AppNavbar />
      <Routes>
        <Route path={config.urls.home.url} element={<Landing />} />
        <Route
          path={config.urls.auth.loginCallback.url}
          element={<LoginCallback />}
        />
        <Route path={config.urls.user.profile.url} element={<PrivateOutlet />}>
          <Route index element={<Profile />} />
        </Route>
        <Route
          path={`${config.urls.user.dashboard.url}/:pk`}
          element={<PrivateOutlet />}
        >
          <Route index element={<Dashboard />} />
        </Route>
        <Route
          path={`${config.urls.user.workspace.url}/:pk`}
          element={<PrivateOutlet />}
        >
          <Route index element={<Workspace />} />
        </Route>
        <Route
          path={`${config.urls.participants.detail.url}/:pk`}
          element={<PrivateOutlet />}
        >
          <Route index element={<ParticipantDetail />} />
        </Route>
        <Route
          path={`${config.urls.clinicians.detail.url}/:pk`}
          element={<PrivateOutlet />}
        >
          <Route index element={<ClinicianDetail />} />
        </Route>
        <Route
          path={`${config.urls.clinicians.add.url}/`}
          element={<PrivateOutlet />}
        >
          <Route index element={<ClinicianAdd />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
