import { Dictionary } from "@reduxjs/toolkit";
import { createApi } from "@reduxjs/toolkit/query/react";
import customFetchBaseQuery from "../../app/customFetchBaseQuery";

export interface Info {
  name: string;
  version: string;
  description: string;
  authors: Dictionary<string>;
  user_manual_url: string;
}

// info service
export const infoApi = createApi({
  reducerPath: "infoApi",
  baseQuery: customFetchBaseQuery,
  tagTypes: ["Info"],
  endpoints: (builder) => ({
    getInfo: builder.query<Info, void>({
      query() {
        return {
          url: "app_info",
          method: "GET",
        };
      },
    }),
  }),
});

export const { useGetInfoQuery } = infoApi;
