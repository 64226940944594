import React from "react";

/* Config */
import config from "../../app/config.json";

/* Components */
import ProfileCardComponent from "./Profile.card.elements";

/* APIs - Utils */
import { User } from "../user/userApi";

type ProfileCardProps = {
  user?: User;
};

export const ProfileCard = ({ user }: ProfileCardProps): JSX.Element => {
  return (
    <>
      <ProfileCardComponent>
        <ProfileCardComponent.Content>
          <ProfileCardComponent.UserShowcase>
            <ProfileCardComponent.UserCallToAction>
              <ProfileCardComponent.UserCallToActionButton
                linkTo={`${config.urls.user.dashboard.url}/${user?.pk}`}
              >
                {config.urls.user.dashboard.displayName}
              </ProfileCardComponent.UserCallToActionButton>
            </ProfileCardComponent.UserCallToAction>
            <ProfileCardComponent.UserPicture>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-24 w-24"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                  clipRule="evenodd"
                />
              </svg>
            </ProfileCardComponent.UserPicture>
            <ProfileCardComponent.UserCallToAction>
              <ProfileCardComponent.UserCallToActionButton
                linkTo={`${config.urls.user.workspace.url}/${user?.pk}`}
              >
                {config.urls.user.workspace.displayName}
              </ProfileCardComponent.UserCallToActionButton>
            </ProfileCardComponent.UserCallToAction>
          </ProfileCardComponent.UserShowcase>
          <ProfileCardComponent.UserDetail>
            <h1 className="text-4xl font-medium text-gray-700">
              {user?.first_name} {user?.last_name}
            </h1>
            <p className="font-light text-gray-600 mt-3">{user?.email}</p>
          </ProfileCardComponent.UserDetail>
        </ProfileCardComponent.Content>
      </ProfileCardComponent>
    </>
  );
};
