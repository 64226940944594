import React from "react";
import { User } from "./userApi";

type WorkspaceJumbotronProps = {
  user: User;
  isLoggedIn: boolean;
  children?: React.ReactNode;
};

export const WorkspaceJumbotron = ({
  user,
  isLoggedIn,
  children,
}: WorkspaceJumbotronProps): JSX.Element => {
  return (
    <>
      <div className="max-w-screen-xl px-4 py-8 mx-auto sm:px-6 lg:px-8">
        {isLoggedIn && user ? (
          <div className="mt-8">
            <h1 className="text-2xl font-bold text-gray-900 sm:text-3xl">
              Welcome Back, {!user.first_name ? user.username : user.first_name}
              !
            </h1>
            <p className="mt-1.5 text-sm text-gray-500">
              Click on an ID to view or edit a record in the list 🗒️.
            </p>
            <p className="mt-1.5 text-sm text-gray-500">
              Need something specific? Try using the 🔍 bar. Need to sort ↕️
              something? Click on the headers!
            </p>
          </div>
        ) : null}
      </div>
      {children}
    </>
  );
};
