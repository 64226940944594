import React, { useEffect, useState } from "react";

/* API */
import {
  useGetPaymentReportQuery,
  TotalSpendByPaymentMethod,
  TotalSpendByMonth,
} from "../payments/PaymentReportApi";

/* Components */
import { Banner, LoadingScreen } from "../../utils/components/Common";

import Grid from "@mui/material/Grid2";
import { Table } from "../user/Workspace.table.elements";

import { PieChartComponent } from "./Payment.piechart.elements";
import { BarChartComponent } from "./Payment.bargraph.elements";
import { PaymentCard } from "./Payment.paymentcard";
import { SectionHeader } from "./Payment.sectionheader";
import { Item } from "./Payment.item";

import { toastQueryError } from "../../utils/toasts";

import { User } from "../user/userApi";

type PaymentReportPanelProps = {
  user: User;
  isLoggedIn: boolean;
};

const PaymentReportComponent = ({
  user,
  isLoggedIn,
}: PaymentReportPanelProps): JSX.Element => {
  // Use the generated hook to fetch the float report data
  const [processing, setProcessing] = useState(true);
  const { data, isLoading, isFetching, isError, error, refetch } =
    useGetPaymentReportQuery();

  useEffect(() => {
    if (isLoading || isFetching) {
      setProcessing(true);
    } else {
      setProcessing(false);
    }
    if (isError && error) {
      toastQueryError(error);
    }
  }, [isLoading, isFetching, isError, error]);

  useEffect(() => {
    refetch();
  }, []);

  // Pre-processed data by payment method
  const totalSpendSummary = data?.total_spend_by_payment_method.find(
    (method) => method.payment_method === "ALL"
  ) as TotalSpendByPaymentMethod;

  const totalSpendByPaymentMethod =
    (data?.total_spend_by_payment_method.filter(
      (method) => method.payment_method !== "ALL"
    ) as TotalSpendByPaymentMethod[]) || [];

  // Pre-processed data by month, this should be removed
  // once the backend is refactored to return floats rather than strings
  const transformTotalSpendByMonth = (
    data: TotalSpendByMonth[] | undefined
  ) => {
    return data?.map((item) => ({
      ...item,
      total_paid_with_fees: parseFloat(item.total_paid_with_fees),
      total_paid_without_fees: parseFloat(item.total_paid_without_fees),
    }));
  };

  const transformedTotalSpendByMonth = transformTotalSpendByMonth(
    data?.total_spend_by_month
  );

  // Setting up colour palette for bar graph
  const totalSpendByMonthKeys = [
    {
      dataKey: "total_paid_with_fees",
      fill: "#8884d8",
      name: "Total Paid with Fees",
    },
    {
      dataKey: "total_paid_without_fees",
      fill: "#82ca9d",
      name: "Total Paid without Fees",
    },
  ];

  // Setting up columns for payment type breakdown
  const paymentBreakdownColumns = React.useMemo(
    () => [
      {
        Header: "Payment Type",
        accessor: "payment_type_label",
        Filter: "",
      },
      {
        Header: "Number of Payments Issued",
        accessor: "no_of_payments",
        Filter: "",
      },
      {
        Header: "Committed (consented status and onward) ($)",
        accessor: "total_committed",
        Filter: "",
      },
      {
        Header: "Total payments issued without admin fees ($)",
        accessor: "total_paid_without_fees",
        Filter: "",
      },
      {
        Header: "Total payments issued with admin fees ($)",
        accessor: "total_paid_with_fees",
        Filter: "",
      },
    ],
    []
  );

  // Display the fetched data
  return (
    <>
      {user && isLoggedIn ? (
        <Grid container spacing={2}>
          {processing && <LoadingScreen />}

          {data && (
            <Grid container>
              {/* PieChart Section */}
              <Grid size={6}>
                <Item
                  style={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <SectionHeader title="Cards Issued" />
                  <PieChartComponent
                    data={data.cards_issued}
                    dataKey="cards_issued"
                    nameKey="payment_method"
                  />
                </Item>
              </Grid>

              {/* Total Spend Section with Sub-Columns */}
              <Grid size={3}>
                {/* Total Spend (ALL) */}

                <PaymentCard
                  data={totalSpendSummary}
                  fontSize="4em"
                ></PaymentCard>
              </Grid>
              <Grid
                size={3}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  gap: "10px",
                }}
              >
                {/* Total Spend by Payment Method */}
                {totalSpendByPaymentMethod.map((method, index) => (
                  <PaymentCard data={method} fontSize="2em" key={index} />
                ))}
              </Grid>

              {/* Total Spend by Month */}
              <Grid size={12}>
                <Item>
                  <SectionHeader title="Total Spend by Payment Type" />
                  <Table
                    columns={paymentBreakdownColumns}
                    data={data.payment_breakdown}
                  />
                </Item>
              </Grid>

              {/* Historical Totals */}
              <Grid size={12}>
                <Item>
                  <SectionHeader title="Total Spend by Month" />
                  <BarChartComponent
                    data={transformedTotalSpendByMonth}
                    xAxisKey="month_year"
                    barKeys={totalSpendByMonthKeys}
                  />
                </Item>
              </Grid>
            </Grid>
          )}
        </Grid>
      ) : (
        <Banner
          heading="Protected Route"
          message="This is a protected route. Authentication required."
        />
      )}
    </>
  );
};

export default PaymentReportComponent;
